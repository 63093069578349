import {EventEmitter, Injectable, Output} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { ClrWizard } from '@clr/angular';
import { ImageSelectorComponent } from 'src/app/shared/image-selector/image-selector.component';
import { ReportHttpService } from '../http/report.http';
import { DashboardStore } from '../stores/dashboardStore';
import { LandingpageStore } from '../stores/landingpage.store';
import { Attraction, AttractionRequest, AttractionResponse } from '../types/attraction';
import { Deadline, Maintenance, MaintenanceResponse, Constant, ConstantIntervallSplit10, ConstantIntervallSplit12 } from '../types/maintenance';
import { Part, PartResponse, PartsResponse } from '../types/part';
import {ReportRequest, ReportResponse} from '../types/report';
import { Unit, UnitResponse } from '../types/unit';
import { requireTrueOrFalse } from '../validators/form.validators';
import { UtilsService } from './utils.service';
import { Router } from '@angular/router';
import { AttractionHttpService } from '../http/attraction.http';
import { EventHttpService } from '../http/event.http';
import { PartHttpService } from '../http/part.http';
import { UnitHttpService } from '../http/unit.http';
import { AttractionMaintenanceHttpService } from '../http/maintenance.http';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  public displayHeader: boolean = false;

  public attraction: AttractionResponse | undefined = undefined;
  public unit: UnitResponse | undefined = undefined;
  public part: PartResponse | undefined = undefined;
  public maintenance: MaintenanceResponse | undefined = undefined;

  public currentAttraction: AttractionResponse | undefined = undefined;
  public currentUnit: UnitResponse | undefined = undefined;
  public currentPart: PartResponse | undefined = undefined;
  public currentMaintenance: Maintenance | undefined = undefined;

  private lockAttraction = false
  private lockUnit = false;
  private lockPart = false;

  public attractionFound = new UntypedFormControl(false, [Validators.requiredTrue]);
  public unitFound = new UntypedFormControl(false, [Validators.requiredTrue]);
  public partFound = new UntypedFormControl(false, [Validators.requiredTrue]);
  public detailsFound = new UntypedFormControl(false, [Validators.requiredTrue]);

  public comment = new UntypedFormControl('');
  public findings = new UntypedFormArray([]);
  public findingComments = new UntypedFormArray([])
  public findingsPictures = new UntypedFormArray([])
  public uploadValidArr: boolean[] = [];
  public measures = new UntypedFormArray([], []);
  public valid: boolean = true

  public wordmarks: { [key: string]: { Page: number, Ref: string } } = {};

  public nextMaintenance: MaintenanceResponse | undefined = undefined;
  public nextPart: PartResponse | undefined = undefined;
  public nextUnit: UnitResponse | undefined = undefined;

  public wizard: ClrWizard | undefined = undefined;
  public skipFindings: boolean = false;

  @Output() finished = new EventEmitter<ReportResponse>();

  private openPromise: Promise<boolean> | undefined;
  constructor(private landingpageStore: LandingpageStore,
    private utils: UtilsService,
    private reportService: ReportHttpService,
    private dashboardStore: DashboardStore,
    private router: Router,
    private attractionService: AttractionHttpService,
    public store: LandingpageStore,
    private maintenanceService: AttractionMaintenanceHttpService,
    private unitService: UnitHttpService,
    private partService: PartHttpService,
    private eventService: EventHttpService) {
    // this.getData()
  }

  public getData(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      this.attractionService.getAllAttractions().subscribe((res) => {

        this.store.attractions = res.attractions.filter(a => !a.attraction.template);
        this.store.attractions.forEach(attraction => {
              this.unitService.getAllUnits(attraction.attraction.id).subscribe(res => {
                this.store.unitsMap = this.store.unitsMap.set(attraction.attraction.id, res.units)
                for (let units of this.store.unitsMap.values()) {
                  units.forEach(unit => {
                    if (!this.store.eventsMap.has(unit.unit.id)) {
                      this.eventService.getAllEvents(unit.unit.parent_id, unit.unit.id).subscribe(res => {
                        this.store.eventsMap = this.store.eventsMap.set(unit.unit.id, res.events.map(e => e.event))
                      })
                    }

                    if (!this.store.partsMap.has(unit.unit.id)) {
                      this.partService.getAllParts(unit.unit.parent_id, unit.unit.id).subscribe(res => {
                        this.store.partsMap = this.store.partsMap.set(unit.unit.id, res.parts)
                        res.parts.forEach(part => {
                          this.maintenanceService.getAllMaintenances(unit.unit.parent_id, unit.unit.id, part.part.id).subscribe(res => {
                            this.store.maintenancesMap = this.store.maintenancesMap.set(part.part.id, res.maintenances)
                          })
                        })
                      })
                    }
                  })
                }

              })
            })
      });
    });
  }

  public updateCurrentMaintenace(report: ReportResponse): Promise<boolean> {
    this.finished.emit(report);
    return new Promise<boolean>((resolve, reject) => {
      if (this.unit !== undefined && this.part !== undefined) {
        this.maintenanceService.getAllMaintenances(this.unit.unit.parent_id, this.unit.unit.id, this.part.part.id).subscribe(res => {
          if (this.part !== undefined) {
            this.store.maintenancesMap = this.store.maintenancesMap.set(this.part.part.id, res.maintenances)
            if (this.dashboardStore.part.part.id === this.part.part.id) {
              this.dashboardStore.maintenances = res;
              this.dashboardStore.maintenance = res.maintenances.find(m => m.maintenance.id === this.dashboardStore.maintenance.maintenance.id) || this.dashboardStore.maintenance
              this.dashboardStore.part.maintenances_due--;
              this.dashboardStore.unit.maintenances_due--;
              this.dashboardStore.attraction.maintenances_due--;
            }
          }
          resolve(true);
        }, err => {
          reject(err);
        })
      }
    })
  }

  public loadMaintenance(attraction: AttractionResponse, unit: UnitResponse, part: PartResponse, maintenance: MaintenanceResponse) {
    this.lockAttraction = false;
    this.lockUnit = false;
    this.lockPart = false;
    this.currentAttraction = attraction;
    this.currentUnit = unit;
    this.currentPart = part;
    this._loadMaintenance(attraction, unit, part, maintenance)
  }

  public _loadMaintenance(attraction: AttractionResponse, unit: UnitResponse, part: PartResponse, maintenance: MaintenanceResponse) {
    this.attraction = attraction;
    this.unit = unit;
    this.part = part;
    this.maintenance = maintenance;
    this.checkCurrent();
    this.displayHeader = true;
    this.skipFindings = false;
    this.wizard?.open();
  }

  public loadMaintenanceList(attraction?: AttractionResponse, unit?: UnitResponse, part?: PartResponse, maintenance?: Maintenance, intervall?: string) {
    if (!this.wizard) return;
    if (attraction) {
      this.attraction = attraction;
      this.currentAttraction = attraction;
      this.lockAttraction = true;
    } else {
      this.attraction = undefined;
      this.lockAttraction = false;
    }

    if (unit) {
      this.unit = unit
      this.currentUnit = unit
      this.lockUnit = true;
    } else {
      this.unit = undefined;
      this.lockUnit = false;
    }

    if (part) {
      this.part = part;
      this.currentPart = part;
      this.lockPart = true;
    } else {
      this.part = undefined;
      this.lockPart = false;
    }

    this.maintenance = undefined;
    this.getNextMaintenance();
    if (this.nextMaintenance) {
      this.displayHeader = true;
      this.loadNext(this.wizard)
    }

  }

  public resolveMaintenance(attraction: AttractionResponse, unit: UnitResponse, part: PartResponse, maintenance: MaintenanceResponse) {
    this.attraction = attraction;
    this.unit = unit;
    this.part = part;
    this.maintenance = maintenance;
    this.lockAttraction = false;
    this.lockUnit = false;
    this.lockPart = false;
    this.skipFindings = false;
    let reportReq: ReportRequest = {
      comment: "",
      findings: maintenance.maintenance?.findings.map((finding, index) => {
        return {
          finding: finding,
          comment: "",
          present: false,
        }
      }),
      measures: [],
      detailed: false,
    };
    this.onComplete(false, undefined, undefined, reportReq);
  }

  public failMaintenance(attraction: AttractionResponse, unit: UnitResponse, part: PartResponse, maintenance: MaintenanceResponse) {
    this.attraction = attraction;
    this.unit = unit;
    this.part = part;
    this.maintenance = maintenance;
    this.lockAttraction = false;
    this.lockUnit = false;
    this.lockPart = false;
    this.checkCurrent(true)
    this.displayHeader = true;
    this.skipFindings = true;
    this.wizard?.open();

  }

  public isSplit(): Constant | undefined {
    if (this.maintenance?.maintenance?.triggers.find(t => t.id === ConstantIntervallSplit12.id) !== undefined) {
      return ConstantIntervallSplit12;
    }
    if (this.maintenance?.maintenance?.triggers.find(t => t.id === ConstantIntervallSplit10.id) !== undefined) {
      return ConstantIntervallSplit10;
    }
    return undefined;
  }
  public getSplit(trig: Constant | undefined): string {
    if (!this.maintenance?.deadline || !trig) {
      return "";
    }
    if (trig.id === ConstantIntervallSplit12.id) {
      return ((this.maintenance?.deadline.success_level % 12) + 1) + "/12"
    }
    if (trig.id === ConstantIntervallSplit10.id) {
      return ((this.maintenance?.deadline.success_level % 10) + 1) + "/10"
    }
    return "";
  }

  public checkSkip(wizard: ClrWizard) {
    if (this.findings.length === 0) {
      wizard.next();
      return;
    }
  }

  public checkCurrent(found?: boolean) {

    this.detailsFound.setValue(false);

    this.attractionFound.setValue(this.attraction && this.attraction.attraction?.id === this.currentAttraction?.attraction?.id || this.store.attractions.length === 1);
    this.attractionFound.valueChanges.subscribe((val) => {
      if (val) {
        this.currentAttraction = this.attraction;
      }
    })
    this.unitFound.setValue(this.currentUnit && this.unit?.unit.id === this.currentUnit?.unit.id);
    this.unitFound.valueChanges.subscribe((val) => {
      if (val) {
        this.currentUnit = this.unit;
      }
    })


    this.partFound.setValue(this.currentPart && this.part?.part.id === this.currentPart?.part.id);
    this.partFound.valueChanges.subscribe((val) => {
      if (val) {
        this.currentPart = this.part;
      }
    })

    if (this.maintenance && this.maintenance?.maintenance.findings) {
      this.findings = new UntypedFormArray(
        this.maintenance?.maintenance.findings.map((f) => new UntypedFormControl(undefined)), [requireTrueOrFalse()]
      );
      this.findingComments = new UntypedFormArray(
        this.maintenance?.maintenance.findings.map(() => new UntypedFormControl(''))
      )
      this.uploadValidArr = this.maintenance?.maintenance.findings.map(() => true)
    }

    if (found) {
      this.attractionFound.setValue(true);
      this.unitFound.setValue(true);
      this.partFound.setValue(true);
      this.detailsFound.setValue(true);
      this.findings.controls.forEach((c) => {
        c.setValue(false);
      });
      return;
    }
  }

  public reset() {
    this.findings = new UntypedFormArray([]);
    this.findingComments = new UntypedFormArray([])
    this.findingsPictures = new UntypedFormArray([])
    this.uploadValidArr = [];
    this.measures = new UntypedFormArray([], []);
    this.valid = true;
    this.skipFindings = false;
    this.wizard?.close();
  }

  public clear() {
    this.reset()
    this.attraction = undefined;
    this.part = undefined;
    this.unit = undefined;
    this.skipFindings = false;
    this.maintenance = undefined;
    this.displayHeader = false;
  }

  getMeasures() {
    let measuresRequired = false;
    if (this.findings.length === 0) {
      measuresRequired = true;
    }
    this.findings.controls.forEach((c) => {
      if (!c.value) {
        measuresRequired = true;
      }
    });


    if (measuresRequired && this.maintenance?.maintenance?.measures) {
      this.measures = new UntypedFormArray(
        this.maintenance.maintenance?.measures.map((f) => new UntypedFormControl(undefined)),
        [requireTrueOrFalse()]
      );
    } else {
      this.measures = new UntypedFormArray([], [requireTrueOrFalse()]);
    }
  }

  getNextMaintenance() {
    this.nextMaintenance = undefined;
    this.nextUnit = undefined;
    this.nextPart = undefined;
    if (this.part) {
      // Check if another maintenance is due on current part
      let maintenances = this.landingpageStore.maintenancesMap.get(this.part.part.id)?.filter((m) => m.maintenance.id !== this.maintenance?.maintenance?.id);
      let maintenancesDue = maintenances?.filter((m) => this.utils.isOverdue(m.deadline))
      if (maintenancesDue && maintenancesDue.length !== 0) {
        this.nextMaintenance = maintenancesDue[0]
        this.nextPart = this.part;
        this.nextUnit = this.unit;
        return
      }
      if (this.nextMaintenance) { return }
      console.log("Maintenance Service: No Maintenance on current part");
    }

    if (this.unit && !this.lockPart) {
      // Check if another maintenance is due on current part group
      this.landingpageStore.partsMap.get(this.unit?.unit.id)?.forEach((part) => {
        if (!this.utils.compareI18n(part.part.group, this.part?.part.group)) { return }
        if (part.part.id === this.part?.part.id) { return }
        if (this.nextMaintenance) { return }
        let maintenances = this.landingpageStore.maintenancesMap.get(part.part.id)
        let maintenancesDue = maintenances?.filter((m) => this.utils.isOverdue(m.deadline))
        if (maintenancesDue && maintenancesDue.length !== 0) {
          this.nextMaintenance = maintenancesDue[0]
          this.nextPart = part;
          this.nextUnit = this.unit;
          return
        }
      })
      if (this.nextMaintenance) { return }
      console.log("Maintenance Service: No Maintenance on group");

      // Check if another maintenance is due on current unit
      this.landingpageStore.partsMap.get(this.unit?.unit.id)?.forEach((part) => {
        if (part.part.id === this.part?.part.id) { return }
        if (this.nextMaintenance) { return }
        let maintenances = this.landingpageStore.maintenancesMap.get(part.part.id);
        let maintenancesDue = maintenances?.filter((m) => this.utils.isOverdue(m.deadline))
        if (maintenancesDue && maintenancesDue.length !== 0) {
          this.nextMaintenance = maintenancesDue[0]
          this.nextPart = part;
          this.nextUnit = this.unit;
          return
        }
      })
      if (this.nextMaintenance) { return }
      console.log("Maintenance Service: No Maintenance on current unit");
    }

    if (this.attraction && !this.lockUnit) {
      // Check if another maintenance is due on unit with same parent
      this.landingpageStore.unitsMap.get(this.attraction.attraction?.id)?.forEach((unit) => {
        if (unit.unit.id !== this.unit?.unit.parent_unit) return
        if (unit.unit.id === this.unit?.unit.id) return
        if (this.nextMaintenance) { return }
        this.landingpageStore.partsMap.get(unit.unit.id)?.forEach((part) => {
          if (part.part.id === this.part?.part.id) { return }
          if (this.nextMaintenance) { return }
          let maintenances = this.landingpageStore.maintenancesMap.get(part.part.id);
          let maintenancesDue = maintenances?.filter((m) => this.utils.isOverdue(m.deadline))
          if (maintenancesDue && maintenancesDue.length !== 0) {
            this.nextMaintenance = maintenancesDue[0]
            this.nextPart = part;
            this.nextUnit = unit;
            return
          }
        })
      })
      if (this.nextMaintenance) { return }
      console.log("Maintenance Service: No Maintenance on parent unit");

      // Check if another maintenance is due on same attraction
      this.landingpageStore.unitsMap.get(this.attraction.attraction?.id)?.forEach((unit) => {
        if (unit.unit.id === this.unit?.unit.id) { return }
        if (this.nextMaintenance) { return }
        this.landingpageStore.partsMap.get(unit.unit.id)?.forEach((part) => {
          if (part.part.id === this.part?.part.id) { return }
          if (this.nextMaintenance) { return }
          let maintenances = this.landingpageStore.maintenancesMap.get(part.part.id);
          let maintenancesDue = maintenances?.filter((m) => this.utils.isOverdue(m.deadline))
          if (maintenancesDue && maintenancesDue.length !== 0) {
            this.nextMaintenance = maintenancesDue[0]
            this.nextPart = part;
            this.nextUnit = unit;
            return
          }
        })
      })
      if (this.nextMaintenance) { return }
      console.log("Maintenance Service: No Maintenance on attraction");
    }
    this.nextMaintenance = undefined;
    this.nextUnit = undefined;
    this.nextPart = undefined;

    // All maintenances done for attraction
  }

  onComplete(next: boolean, wizard?: ClrWizard, imageSelector?: ImageSelectorComponent[], reportReq?: ReportRequest) {

    if (this.maintenance && this.part && this.unit && this.attraction) {
      if (reportReq === undefined) {
        reportReq = {
          comment: this.comment.value,
          detailed: !this.skipFindings,
          findings: this.maintenance.maintenance?.findings.map((finding, index) => {
            return {
              finding: finding,
              comment: this.findingComments.controls[index].value,
              present: !this.findings.controls[index].value,
            }
          }),
          measures: this.measures.controls.map((measure, index) => {
            if (this.maintenance) {
              return {
                measure: this.maintenance.maintenance?.measures[index],
                required: true,
                performed: this.measures.controls[index].value,
              }
            }
            return {} as any
          })
        };
      }

      this.reportService
        .createReport(this.attraction.attraction.id, this.unit.unit.id, this.part.part.id, this.maintenance?.maintenance.id, reportReq)
        .subscribe((report) => {
          if (this.attraction?.attraction?.id && this.unit?.unit.id && this.part?.part.id && this.maintenance?.maintenance?.id) {
            this.dashboardStore.reports.reports = [report, ...this.dashboardStore.reports.reports]
          }
          this.updateCurrentMaintenace(report).then(() => {
            if (imageSelector) {
              return this.onUpload(imageSelector, report.report.id)
            } else {
              return true
            }
          }).then(() => {
            if (!next) {
              this.clear();
            } else {
              if (wizard) {
                this.loadNext(wizard);
              }
            }
          }).catch((err) => {
            if (wizard) {
              wizard.open();
            }
          })

        });
    }
  }

  onUpload(imageSelectors: ImageSelectorComponent[], id: string) {
    return new Promise<boolean>((resolve, reject) => {
      let uploadPromises: Promise<boolean[]>[] = []
      imageSelectors.forEach((imageSelector) => {
        const uploadPromise = imageSelector.onUpload()
        uploadPromises.push(uploadPromise)
      })
      Promise.all(uploadPromises).then((values) => {
        let valuesFlat = values.reduce((acc, val) => acc.concat(val), [])
        if (!valuesFlat.includes(false)) {
          resolve(true)
        } else {
          reject(false)
        }
      })
    })
  }

  loadNext(wizard: ClrWizard) {
    if (this.attraction && this.nextUnit && this.nextPart && this.nextMaintenance) {
      wizard.reset();
      this.reset()
      this._loadMaintenance(this.attraction, this.nextUnit, this.nextPart, this.nextMaintenance)
      wizard.open();
    } else {
      this.reset();
    }
  }




}
