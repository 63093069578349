import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
    private apiUrl =
        location.protocol + '//' + location.hostname + (location.port ? ':' + environment.API_PORT : '') + '/api/v1/';
    private staticUrl =
        location.protocol + '//' + location.hostname + (location.port ? ':' + environment.STATIC_PORT : '');
    private fileUrl =
        location.protocol + '//' + location.hostname + (location.port ? ':' + environment.API_PORT : '');
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let url;

        if (req.url.startsWith('/assets')) {
            url = this.staticUrl + req.url;
        } else if (req.url.startsWith('/static')) {
            url = this.fileUrl + req.url;
        } else {
            url = this.apiUrl + req.url;
        }
        const urlReq = req.clone({
            url: url,
        });

        return next.handle(urlReq);
    }
}
