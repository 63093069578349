import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AttractionResponse } from '../types/attraction';
import { Event, EventTypeIBN } from '../types/event';
import { MaintenanceResponse } from '../types/maintenance';
import { PartResponse } from '../types/part';
import { Report } from '../types/report';
import { Ridelog } from '../types/ridelog';
import { UnitResponse } from '../types/unit';

@Injectable({
    providedIn: 'root',
})
export class LandingpageStore {

    constructor() { }


    // Attractions: Getter and Setter
    private attractionsSubject: BehaviorSubject<AttractionResponse[]> = new BehaviorSubject<AttractionResponse[]>([])
    set attractions(attractions: AttractionResponse[]) { this.attractionsSubject.next(attractions) }
    get attractions(): AttractionResponse[] { return this.attractionsSubject.value }
    get $attractions(): Observable<AttractionResponse[]> { return new Observable((fn) => this.attractionsSubject.subscribe(fn)) }
    getAttraction(id: string): AttractionResponse | undefined {
        const arr = this.attractionsSubject.value.filter((g) => g.attraction.id === id); if (arr.length === 1) { return arr[0] } else { return undefined; }
    }

    // Units: Getter and Setter
    private unitsMapSubject: BehaviorSubject<Map<string, UnitResponse[]>> = new BehaviorSubject<Map<string, UnitResponse[]>>(new Map<string, UnitResponse[]>())
    private unitsSubject: BehaviorSubject<UnitResponse[]> = new BehaviorSubject<UnitResponse[]>([])
    set unitsMap(unitsMap: Map<string, UnitResponse[]>) {
        this.unitsMapSubject.next(unitsMap)
        let units: UnitResponse[] = []
        unitsMap.forEach(arr => units = units.concat(...arr))
        this.unitsSubject.next(units)
    }
    get unitsMap(): Map<string, UnitResponse[]> { return this.unitsMapSubject.value }
    get $unitsMap(): Observable<Map<string, UnitResponse[]>> { return new Observable((fn) => this.unitsMapSubject.subscribe(fn)) }
    get units(): UnitResponse[] { return this.unitsSubject.value }
    get $units(): Observable<UnitResponse[]> { return new Observable((fn) => this.unitsSubject.subscribe(fn)) }
    getUnit(id: string): UnitResponse | undefined {
        const arr = this.unitsSubject.value.filter((g) => g.unit.id === id); if (arr.length === 1) { return arr[0] } else { return undefined; }
    }

    // Event: Getter and Setter
    private eventsMapSubject: BehaviorSubject<Map<string, Event[]>> = new BehaviorSubject<Map<string, Event[]>>(new Map<string, Event[]>())
    private eventsSubject: BehaviorSubject<Event[]> = new BehaviorSubject<Event[]>([])
    set eventsMap(eventsMap: Map<string, Event[]>) {
        this.eventsMapSubject.next(eventsMap)
        let events: Event[] = []
        eventsMap.forEach(arr => events = events.concat(...arr))
        this.eventsSubject.next(events)
    }
    get eventsMap(): Map<string, Event[]> { return this.eventsMapSubject.value }
    get $eventsMap(): Observable<Map<string, Event[]>> { return new Observable((fn) => this.eventsMapSubject.subscribe(fn)) }
    get events(): Event[] { return this.eventsSubject.value }
    get $events(): Observable<Event[]> { return new Observable((fn) => this.eventsSubject.subscribe(fn)) }
    getEvent(id: string): Event | undefined {
        const arr = this.eventsSubject.value.filter((g) => g.id === id); if (arr.length === 1) { return arr[0] } else { return undefined; }
    }

    // Ridelog: Getter and Setter
    private ridelogsMapSubject: BehaviorSubject<Map<string, Ridelog[]>> = new BehaviorSubject<Map<string, Ridelog[]>>(new Map<string, Ridelog[]>())
    private ridelogsSubject: BehaviorSubject<Ridelog[]> = new BehaviorSubject<Ridelog[]>([])
    set ridelogsMap(ridelogsMap: Map<string, Ridelog[]>) {
        this.ridelogsMapSubject.next(ridelogsMap)
        let ridelogs: Ridelog[] = []
        ridelogsMap.forEach(arr => ridelogs = ridelogs.concat(...arr))
        this.ridelogsSubject.next(ridelogs)
    }
    get ridelogsMap(): Map<string, Ridelog[]> { return this.ridelogsMapSubject.value }
    get $ridelogsMap(): Observable<Map<string, Ridelog[]>> { return new Observable((fn) => this.ridelogsMapSubject.subscribe(fn)) }
    get ridelogs(): Ridelog[] { return this.ridelogsSubject.value }
    get $ridelogs(): Observable<Ridelog[]> { return new Observable((fn) => this.ridelogsSubject.subscribe(fn)) }
    getRidelog(id: string): Ridelog | undefined {
        const arr = this.ridelogsSubject.value.filter((g) => g.id === id); if (arr.length === 1) { return arr[0] } else { return undefined; }
    }

    // Parts: Getter and Setter
    private partsMapSubject: BehaviorSubject<Map<string, PartResponse[]>> = new BehaviorSubject<Map<string, PartResponse[]>>(new Map<string, PartResponse[]>())
    private partsSubject: BehaviorSubject<PartResponse[]> = new BehaviorSubject<PartResponse[]>([])
    set partsMap(partsMap: Map<string, PartResponse[]>) {
        this.partsMapSubject.next(partsMap)
        let parts: PartResponse[] = []
        partsMap.forEach(arr => parts = parts.concat(...arr))
        this.partsSubject.next(parts)
    }
    get partsMap(): Map<string, PartResponse[]> { return this.partsMapSubject.value }
    get $partsMap(): Observable<Map<string, PartResponse[]>> { return new Observable((fn) => this.partsMapSubject.subscribe(fn)) }
    get parts(): PartResponse[] { return this.partsSubject.value }
    get $parts(): Observable<PartResponse[]> { return new Observable((fn) => this.partsSubject.subscribe(fn)) }
    getPart(id: string): PartResponse | undefined {
        const arr = this.partsSubject.value.filter((g) => g.part.id === id); if (arr.length === 1) { return arr[0] } else { return undefined; }
    }

    // Maintenances: Getter and Setter
    private maintenancesMapSubject: BehaviorSubject<Map<string, MaintenanceResponse[]>> = new BehaviorSubject<Map<string, MaintenanceResponse[]>>(new Map<string, MaintenanceResponse[]>())
    private maintenancesSubject: BehaviorSubject<MaintenanceResponse[]> = new BehaviorSubject<MaintenanceResponse[]>([])
    set maintenancesMap(maintenancesMap: Map<string, MaintenanceResponse[]>) {
        this.maintenancesMapSubject.next(maintenancesMap)
        let maintenances: MaintenanceResponse[] = []
        maintenancesMap.forEach(arr => maintenances = maintenances.concat(...arr))
        this.maintenancesSubject.next(maintenances)
    }
    get maintenancesMap(): Map<string, MaintenanceResponse[]> { return this.maintenancesMapSubject.value }
    get $maintenancesMap(): Observable<Map<string, MaintenanceResponse[]>> { return new Observable((fn) => this.maintenancesMapSubject.subscribe(fn)) }
    get $maintenances(): Observable<MaintenanceResponse[]> { return new Observable((fn) => this.maintenancesSubject.subscribe(fn)) }
    get maintenances(): MaintenanceResponse[] { return this.maintenancesSubject.value }
    getMaintenance(id: string): MaintenanceResponse | undefined {
        const arr = this.maintenancesSubject.value.filter((g) => g.maintenance.id === id); if (arr.length === 1) { return arr[0] } else { return undefined; }
    }

    // Helpers
    hasIBN(id: string) {
        return this.eventsMap.get(id)?.filter((e) => e.event_type === EventTypeIBN).length === 0 ? false : true;
    }



}
