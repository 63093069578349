import { AbstractControl, UntypedFormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let checked = 0;
        const formArray = control as UntypedFormArray;
        Object.keys(formArray.controls).forEach((key) => {
            const control = formArray.get(key);

            if (control && control.value === true) {
                checked++;
            }
        });

        if (checked < minRequired) {
            return {
                required: true,
            };
        }

        return null;
    };
}

export function requireCheckboxesToTrueOrEmpty(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const formArray = control as UntypedFormArray;
        let x = true;
        Object.keys(formArray.controls).forEach((key) => {
            const control = formArray.get(key);

            if (control && control.value === false) {
                x = false;
            }
        });

        if (!x) {
            return {
                required: true,
            };
        }

        return null;
    };
}

export function requireTrueOrFalse(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const formArray = control as UntypedFormArray;
        let x = true;

        Object.keys(formArray.controls).forEach((key) => {
            const control = formArray.get(key);
            if (control && (control.value === undefined || control.value === null)) {
                x = false;
            }
        });

        if (!x) {
            return {
                required: true,
            };
        }

        return null;
    };
}
