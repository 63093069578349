<div class="card m-0">
  <div class="card-header flex-row-s gap-6">
    <cds-icon shape="user" size="md"></cds-icon>
    <span>{{user?.user_mail}}</span>
    <span class="label label-info">{{user?.group_name}}</span>
    <span *ngIf="user?.user_type === 1" class="label label-warning">{{ 'Constants.UserRoles.View' | translate }}</span>
    <span *ngIf="user?.user_type === 2" class="label label-warning">{{ 'Constants.UserRoles.Edit' | translate }}</span>
    <span *ngIf="user?.user_type === 3" class="label label-warning">{{ 'Constants.UserRoles.Manage' | translate }}</span>
    <span *ngIf="user?.user_type === 4" class="label label-warning">{{ 'Constants.UserRoles.Admin' | translate }}</span>
    <span class="label label-warning" *ngIf="user?.user_status === 'invited'" style="margin-left: 12px">{{'Misc.Outstanding' | translate}}</span>
    <span class="label label-danger" *ngIf="user?.user_status === 'expired'" style="margin-left: 12px">{{'Misc.Expired' | translate}}</span>
    <span *ngIf="user?.id === authStore.userInfo.id" class="label label-success">{{'Misc.You' | translate}}</span>
  </div>
  <div class="card-block">
    <div class="flex-row-b">
      <div style="width: 50%">
        <h4>{{'Header.UserDetails' | translate}}</h4>
          <p>
            {{'Grid.Header.Benutzername' | translate}}: {{user?.user_first_name}} {{user?.user_last_name}} <br>
            {{'Grid.Header.E-Mail' | translate}}: {{user?.user_mail}}

          </p>
        <h4>{{'Header.AuthLog' | translate}}</h4>
        <table class="table table-compact">
          <thead>
          <tr>
            <th class="left">{{'Misc.Date' | translate}}</th>
            <th class="left">{{'Misc.IP' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let log of authLog | slice:0:authLogSpliceLimit">
            <td class="left">{{utils.toShortDateString(log.time)}} {{utils.toTimeString(log.time)}}</td>
            <td class="left">{{log.ip}}</td>
          </tr>
          <tr *ngIf="authLog.length > authLogSpliceLimit">
            <td class="left" colspan="2"><a (click)="authLogSpliceLimit=authLog.length">{{'Misc.ShowAll' | translate}}</a></td>
          </tr>
        </table>

      </div>
      <div style="width: 50%">
        <h4>{{'Header.UserLog' | translate}}</h4>
        <table class="table table-compact">
          <thead>
          <tr>
            <th class="left">{{'Misc.Date' | translate}}</th>
            <th class="left">{{'Misc.Type' | translate}}</th>
            <th class="left">{{'Misc.Object' | translate}}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let change of userLog | slice:0:userLogSpliceLimit">
            <td class="left">{{utils.toShortDateString(change.change.time)}} {{utils.toTimeString(change.change.time)}}</td>
            <td class="left">
              <span *ngIf="change.change.type === 'create'" class="label label-success m-0">{{ 'Constants.Change.ChangeTypeCreate' | translate }}</span>
              <span *ngIf="change.change.type === 'update'" class="label label-warning m-0">{{ 'Constants.Change.ChangeTypeEdit' | translate }}</span>
              <span *ngIf="change.change.type === 'delete'" class="label label-warning m-0">{{ 'Constants.Change.ChangeTypeDelete' | translate }}</span>
            </td>
            <td class="left">
              <span class="label label-info">{{change.object_type}}</span>
            </td>
          </tr>
          <tr *ngIf="userLog.length > userLogSpliceLimit">
            <td class="left" colspan="3"><a (click)="userLogSpliceLimit=userLog.length">{{'Misc.ShowAll' | translate}}</a></td>
          </tr>
        </table>

      </div>

    </div>
  </div>
  <div class="card-footer flex-row-s gap-6">
      <button type="button" class="btn btn-outline" (click)="showPasswordModal = true" *ngIf="user?.id === authStore.userInfo.id"><cds-icon shape="lock" ></cds-icon>{{'Actions.ChangePassword' | translate}}</button>
      <button type="button" class="btn btn-outline" (click)="showUserModal = true" *ngIf="authStore.isUser(3) || user?.id === authStore.userInfo.id"><cds-icon shape="pencil" ></cds-icon>{{'Actions.Edit' | translate}}</button>
      <button type="button" class="btn btn-danger" (click)="logout()" *ngIf="user?.id === authStore.userInfo.id"><cds-icon shape="logout"></cds-icon>{{'Actions.LogOut' | translate}}</button>
  </div>
</div>

<app-change-password-modal [(show)]="showPasswordModal"></app-change-password-modal>
<app-user-wizard
  [(show)]="showUserModal"
  [user]="user">
</app-user-wizard>
