import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FileHttpService } from 'src/app/core/http/file.http';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { UrlService } from 'src/app/core/services/url.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FileInfo, FileInfoRequest } from 'src/app/core/types/file';


export interface SelectedFile {
  file: File,
  preview: string
  obs?: Subscription
}

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent {

  constructor(private fileSerive: FileHttpService, private utils: UtilsService, public urlService: UrlService, private uploadService: FileUploadService) {
    this.form.valueChanges.subscribe(() => {
      this.valid = this.form.valid
      this.validChange.emit(this.form.valid)
    })
  }

  public selectedFiles: SelectedFile[] = []
  @Input() accept: string = ""
  public _parent: string = ""
  @Input()
  set parent(parent: string | undefined) {
    if (parent) {
      this._parent = parent;
      this.fileInfo = [];
      this.fileSerive.getAllFileInfos(parent).subscribe((res) => {
        this.fileInfo = res.files.map((f) => f.file).filter((f) => {
          if (this.type === "image") {
            return f.file_type === "PNG" || f.file_type === "JPG" || f.file_type === "JPEG"
          }
          if (this.type === "pdf") {
            return f.file_type === "PDF"
          }
          if (this.type === "text") {
            return f.file_type === "DOCX" || f.file_type === "TXT"
          }
          return false
        })
      })
    }
  }

  @Input() multiple: boolean = true;
  @Input() valid: boolean = true;
  @Input() type: string = "image";
  @Output() validChange = new EventEmitter<boolean>()
  public fileInfo: FileInfo[] = []
  public form = new UntypedFormGroup({
    fileInfo: new UntypedFormArray([])
  })
  @Input() group: number = 0;



  onUpload(): Promise<boolean[]> {
    let promises: Promise<boolean>[] = []
    this.selectedFiles.forEach((file, i) => {
      const fileInfo = {
        file_name: (<UntypedFormArray>this.form.get('fileInfo'))?.controls[i].value.name,
        file_description: (<UntypedFormArray>this.form.get('fileInfo'))?.controls[i].value.description,
        index: i,
        group: (<UntypedFormArray>this.form.get('fileInfo'))?.controls[i].value.group || this.group,
        parent_id: this._parent,
        owner_group: "",
      }
      const uploadPromise = new Promise<boolean>((resolve, reject) => {
        this.uploadService.add(file, fileInfo).then((value) => {
          if (value.file?.id) {
            this.fileInfo.push(value.file)
            this.onRemove(this.selectedFiles.indexOf(file))
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
      promises.push(uploadPromise)
    })
    return Promise.all<boolean>(promises)
  }

  onFileSelected(event: Event) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = target.files;
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        let file = fileList.item(i)
        if (file) {
          let selectedFile = {
            file: file,
            preview: "",
          }
          this.selectedFiles.push(selectedFile)
          if (this.type === "image") {
            this.generatePreview(this.selectedFiles.indexOf(selectedFile))
          }
          let arr = this.form.get('fileInfo') as UntypedFormArray
          arr.push(
            new UntypedFormGroup({
              name: new UntypedFormControl(selectedFile.file.name, Validators.required),
              description: new UntypedFormControl(''),
              group: new UntypedFormControl('')
            })
          )
        }
      }
    }
    target.value = ""
  }

  generatePreview(i: number) {
    const reader = new FileReader();
    reader.onload = () => {
      this.selectedFiles[i].preview = reader.result as string;
    }
    reader.readAsDataURL(this.selectedFiles[i].file)
  }

  getFileSize(size: number): string {
    let mb = size / 1024 / 1024
    return mb.toFixed(2)
  }

  onRemove(index: number) {
    this.selectedFiles.splice(index, 1);
    (<UntypedFormArray>this.form.get('fileInfo')).controls.splice(index, 1)
    if (this.selectedFiles.length === 0) {
      this.valid = true
      this.validChange.emit(this.valid)
    }
  }

  onDelete(index: number) {
    this.fileSerive.deleteFile(this.fileInfo[index].id).subscribe((res) => {
      this.fileInfo.splice(index, 1);
    })
  }

  getControlGroup(i: number): UntypedFormGroup {
    return <UntypedFormGroup>(<UntypedFormArray>this.form.get('fileInfo')).controls[i];
  }

  openPDF(file: FileInfo) {
    window.open(this.urlService.getUrl('/static/' + file.id), '_blank')
  }

  openWord(file: FileInfo) {
    window.open(this.urlService.getUrl('/static/' + file.id), '_blank')
  }


}
