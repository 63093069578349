import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { ClrWizard, ClrWizardPage } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { MaintenanceService } from 'src/app/core/services/maintenance.service';
import { InfoModalService } from 'src/app/core/services/info-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DashboardStore } from 'src/app/core/stores/dashboardStore';
import { GroupStore } from 'src/app/core/stores/group.store';
import { ImageSelectorComponent } from 'src/app/shared/image-selector/image-selector.component';
import { UrlService } from 'src/app/core/services/url.service';
import { AttractionHttpService } from 'src/app/core/http/attraction.http';

@Component({
    selector: 'app-do-maintenance-wizard',
    templateUrl: './do-maintenance-wizard.component.html',
    styleUrls: ['./do-maintenance-wizard.component.scss'],
})
export class DoMaintenanceWizardComponent implements OnInit {

    @ViewChild('doMaintenanceWizard', { static: true }) wizard!: ClrWizard;
    @ViewChild('pageOne', { static: true }) page1!: ClrWizardPage;
    @ViewChild('pageTwo', { static: true }) page2!: ClrWizardPage;
    @ViewChild('pageThree', { static: true }) page3!: ClrWizardPage;
    @ViewChild('pageFour', { static: true }) page4!: ClrWizardPage;
    @ViewChildren(ImageSelectorComponent) imageSelectors: ImageSelectorComponent[] = [];

    constructor(
        public translate: TranslateService,
        public dashboardStore: DashboardStore,
        public info: InfoModalService,
        public groupStore: GroupStore,
        public utils: UtilsService,
        private urlService: UrlService,
        private attractionService: AttractionHttpService,
        public maintenanceService: MaintenanceService) { }


    public doCustomClick(buttonType: string): void {
        if ("custom-finish" === buttonType) {
            this.maintenanceService.onComplete(true, this.wizard, this.imageSelectors, undefined);
        }
    }

    ngOnInit(): void {
        this.maintenanceService.wizard = this.wizard;
    }

    checkWizard(open: boolean) {
        if (!open) return;

        if (this.wizard && this.maintenanceService.attractionFound.value && this.maintenanceService.unitFound.value) {
            this.wizard.currentPage = this.page2;
        }
        if (this.wizard && this.maintenanceService.partFound.value && this.maintenanceService.detailsFound.value) {
            this.wizard.currentPage = this.page3;
        }
        if (this.wizard && this.maintenanceService.skipFindings) {
            this.wizard.currentPage = this.page4;
        }
    }

    public manualId: string = '';

    onComplete() {
        this.maintenanceService.onComplete(false, this.wizard, this.imageSelectors, undefined);
    }

    onCancel() {
        this.wizard.reset();
        this.maintenanceService.wizard?.close();
    }

    getFormControl(a: AbstractControl | null): UntypedFormControl {
        if (a) {
            return a as UntypedFormControl;
        } else {
            return new UntypedFormControl
        }

    }

    getNextMaintenanceDescription(): string {
        return `${this.maintenanceService.nextUnit?.full_code_letter} → ${this.utils.getI18nString(this.maintenanceService.nextPart?.part.group, this.translate.currentLang)} → ${this.utils.getI18nString(this.maintenanceService.nextPart?.part.name, this.translate.currentLang)}`
    }

}
