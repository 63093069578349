import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import {ChangesResponse, SuccessConfirmation} from '../types/base';
import { UserInfo, UserResponse, UserRequest, UsersResponse, UserRequestFull } from '../types/user';

@Injectable({
    providedIn: 'root',
})
export class UserHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getUser(id: string): Observable<UserResponse> {
        return this.http.get<UserResponse>('users/' + id).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllUsers(): Observable<UsersResponse> {
        return this.http.get<UsersResponse>('users/').pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createUser(user: UserRequestFull): Observable<UserResponse> {
        return this.http.post<UserResponse>('users/', user).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    updateUser(id: string, user: UserRequest): Observable<UserResponse> {
        return this.http.put<UserResponse>('users/' + id, user).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    changePassword(id: string, password: string): Observable<SuccessConfirmation> {
        return this.http
            .put<UserResponse>('users/' + id + '/password', { user_password: password })
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deleteUser(id: string): Observable<SuccessConfirmation> {
        return this.http.delete<SuccessConfirmation>('users/' + id).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getUserLog(id: string): Observable<ChangesResponse> {
        return this.http.get<any>('users/' + id + '/log').pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }
}
