import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InfoModalService } from 'src/app/core/services/info-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-new-member-modal',
  templateUrl: './new-member-modal.component.html',
  styleUrls: ['./new-member-modal.component.scss']
})
export class NewMemberModalComponent {
  constructor(public service: InfoModalService, public utils: UtilsService, public router: Router) { }

  applyChanges() {
    this.service.applyChanges().then((res) => {
      this.router.navigateByUrl(this.router.url);
    })
  }

}
