import { Injectable } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { AttractionHttpService } from '../http/attraction.http';
import { AttractionMaintenanceHttpService } from '../http/maintenance.http';
import { PartHttpService } from '../http/part.http';
import { UnitHttpService } from '../http/unit.http';
import { Attraction, AttractionRequest, AttractionResponse } from '../types/attraction';
import { Alteration, ApplyRequest, Change } from '../types/base';
import { Maintenance, MaintenanceResponse } from '../types/maintenance';
import { Part, PartResponse } from '../types/part';
import { Unit, UnitResponse } from '../types/unit';


@Injectable({
  providedIn: 'root'
})
export class InfoModalService {

  public showResourceInfo: boolean = false
  public changes: Change[] = []
  constructor(
    private attractionHttp: AttractionHttpService,
    private unitHttp: UnitHttpService,
    private partHttp: PartHttpService,
    private maintenanceHttp: AttractionMaintenanceHttpService,
  ) { }

  displayInformation(res: any) {
    this.changes = res.changes
    this.showResourceInfo = true
  }

  public showResourceChange: boolean = false
  public showNewMembers: boolean = false
  public alterations: { change: Alteration, form: FormControl }[] = []
  public newMembers: { id: string, field1: string, field2: string, form: FormControl }[] = []
  public resType: string = '';
  public attraction_id: string = '';
  public unit_id: string | undefined = '';
  public part_id: string | undefined = '';
  public maintenance_id: string | undefined = '';

  public interdediate: boolean = false;
  public interdediateMembers: boolean = false;
  public allCheckbox: FormControl = new FormControl(false);
  public allCheckboxMembers: FormControl = new FormControl(false);
  displayChange(res: AttractionResponse | UnitResponse | PartResponse | MaintenanceResponse, resType: string,
    attraction_id: string, unit_id?: string, part_id?: string, maintenance_id?: string) {
    this.interdediate = false;
    this.allCheckbox.setValue(false);
    this.showResourceChange = true;
    this.showNewMembers = false;
    this.newMembers = [];
    this.changes = [];
    this.alterations = res.changes.map((change: Alteration) => {
      return { change, form: new FormControl<boolean>(false) }
    });
    this.resType = resType;
    this.attraction_id = attraction_id;
    this.unit_id = unit_id;
    this.part_id = part_id;
    this.maintenance_id = maintenance_id;
  }

  displayNewMember(res: Unit[] | Part[] | Maintenance[], resType: string, attraction_id: string, unit_id?: string, part_id?: string, maintenance_id?: string) {
    this.interdediate = false;
    this.allCheckbox.setValue(false);
    this.showNewMembers = true;
    this.showResourceChange = false;
    this.newMembers = [];
    this.changes = [];
    switch (resType) {
      case 'Attraction':
        res = res as Unit[];
        this.newMembers = res.map((obj: Unit) => {
          return { id: obj.id, field1: obj.code_letter, field2: obj.name.de, form: new FormControl<boolean>(false) }
        });
        break;
      case 'Unit':
        res = res as Part[];
        this.newMembers = res.map((obj: Part) => {
          return { id: obj.id, field1: obj.group.de, field2: obj.name.de, form: new FormControl<boolean>(false) }
        });
        break;
      case 'Part':
        res = res as Maintenance[];
        this.newMembers = res.map((obj: Maintenance) => {
          return { id: obj.id, field1: obj.description.de, field2: obj.triggers.map(t => t.id).join(', '), form: new FormControl<boolean>(false) }
        });
        break;
    }
    this.resType = resType;
    this.attraction_id = attraction_id;
    this.unit_id = unit_id;
    this.part_id = part_id;
    this.maintenance_id = maintenance_id;
  }

  checkAll($event: Event) {
    this.alterations.forEach(change => {
      change.form.setValue(($event.target as HTMLInputElement).checked)
    })
  }

  check($event: Event) {
    this.interdediate = this.alterations.some(change => change.form.value !== this.allCheckbox.value)
  }

  checkAllMembers($event: Event) {
    this.newMembers.forEach(change => {
      change.form.setValue(($event.target as HTMLInputElement).checked)
    })
  }

  checkMembers($event: Event) {
    this.interdediateMembers = this.newMembers.some(change => change.form.value !== this.allCheckbox.value)
  }

  applyChanges(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let req: ApplyRequest = {
        changes: this.alterations.filter(change => change.form.value).map(change => change.change),
        new_members: this.newMembers.filter(member => member.form.value).map(member => member.id)
      }

      switch (this.resType) {
        case 'Attraction':
          this.attractionHttp.applyAttraction(this.attraction_id, req).subscribe(res => {
            resolve(true)
            this.cancel()

          });
          break;
        case 'Unit':
          if (!this.unit_id) return;
          this.unitHttp.applyUnit(this.attraction_id, this.unit_id, req).subscribe(res => {
            this.cancel()
            resolve(true)
          });
          break;
        case 'Part':
          if (!this.unit_id || !this.part_id) return;
          this.partHttp.applyPart(this.attraction_id, this.unit_id, this.part_id, req).subscribe(res => {
            this.cancel()
            resolve(true)
          });
          break;
        case 'Maintenance':
          if (!this.unit_id || !this.part_id || !this.maintenance_id) return;
          this.maintenanceHttp.applyMaintenance(this.attraction_id, this.unit_id, this.part_id, this.maintenance_id, req).subscribe(res => {
            this.cancel()
            resolve(true)
          });
          break;
      }
    })
  }

  cancel() {
    this.showResourceChange = false;
    this.showNewMembers = false;
    this.changes = [];
    this.allCheckbox.setValue(false);
    this.interdediate = false;
    this.resType = '';
    this.attraction_id = '';
    this.unit_id = '';
    this.part_id = '';
    this.maintenance_id = '';
  }
}
