import { Component, OnInit } from '@angular/core';
import { InfoModalService } from 'src/app/core/services/info-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-resource-info-modal',
  templateUrl: './resource-info-modal.component.html',
  styleUrls: ['./resource-info-modal.component.scss']
})
export class ResourceInfoModalComponent {

  constructor(public service: InfoModalService, public utils: UtilsService) { }


}
