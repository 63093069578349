import { Component } from '@angular/core';
import { ImageDisplayService } from 'src/app/core/services/image-display.service';

@Component({
  selector: 'app-image-display',
  templateUrl: './image-display.component.html',
  styleUrls: ['./image-display.component.scss']
})
export class ImageDisplayComponent {

  constructor(public service: ImageDisplayService) { }



}
