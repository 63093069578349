import { Component, EventEmitter, Input, Output } from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AuthHttpService } from 'src/app/core/http/auth.http';
import { confirmPasswordValidator } from 'src/app/core/validators/user.validator';
import {CalendarMonthModule} from "angular-calendar";
import {
  ClrCheckboxModule,
  ClrCommonFormsModule,
  ClrDatepickerModule, ClrModalModule,
  ClrPasswordModule,
  ClrRadioModule
} from "@clr/angular";
import {NgForOf, NgIf} from "@angular/common";
import {SharedModule} from "../../shared.module";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-change-password-modal',
    standalone: true,
    imports: [
      ClrCommonFormsModule,
      FormsModule,
      NgForOf,
      NgIf,
      ReactiveFormsModule,
      TranslateModule,
      ClrPasswordModule,
      ClrModalModule
    ],
    templateUrl: './change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent {
    public _show: boolean = false;
    public _showChange(show: boolean): void {
        this._show = show
        this.showChange.emit(this._show)
    }

    @Input()
    set show(open: boolean) {
        this._show = open
    }

    @Output() showChange = new EventEmitter<boolean>();

    public passwordForm = new UntypedFormGroup(
        {
            old_password: new UntypedFormControl('', Validators.required),
            user_password: new UntypedFormControl('', Validators.required),
            confirm_password: new UntypedFormControl('', Validators.required),
        },
        { validators: confirmPasswordValidator() }
    );

    constructor(private authService: AuthHttpService) { }



    onConfirm() {
        this.authService
            .putPasswordChange({
                old_password: this.passwordForm.value.old_password,
                user_password: this.passwordForm.value.user_password,
            })
            .subscribe((auth) => {
                this.onCancel();
            }, (error) => {
                this.passwordForm.get('old_password')?.setErrors({ wrong: true })
            });
    }

    onCancel() {
        this.passwordForm.reset();
        this.show = false;
        this.showChange.emit(this.show);
    }
}
