import { Injectable } from "@angular/core";
import {ActivatedRouteSnapshot, EventType, Router} from "@angular/router";
import { UnitResponse, UnitsResponse } from "../types/unit";
import { DashboardStore } from "../stores/dashboardStore";
import {AttractionResponse, AttractionsResponse} from "../types/attraction";
import {PartResponse, PartsResponse} from "../types/part";
import {MaintenanceResponse, MaintenancesResponse} from "../types/maintenance";
import {EventsResponse} from "../types/event";
import {ReportsResponse} from "../types/report";

@Injectable({ providedIn: 'root' })
export class UnloadResolver  {
    constructor(private store: DashboardStore, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot): boolean {
        let subscription = this.router.events.subscribe((event) => {
          if (event.type === EventType.NavigationEnd) {
            this.unload(route.data.unload)
            subscription.unsubscribe();
          }
        });
        return route.data.unload;
    }

    unload(unload_data: string[]) {
      if (unload_data.includes('attractions') || (unload_data.includes('*') && !unload_data.includes('^attractions'))) {
        this.store.attractions = { attractions: [] } as unknown as AttractionsResponse;
      }
      if (unload_data.includes('attraction') || unload_data.includes('*') && !unload_data.includes('^attraction')) {
        this.store.attraction = {} as AttractionResponse;
      }
      if (unload_data.includes('units') || (unload_data.includes('*') && !unload_data.includes('^units'))) {
        this.store.units = { units: [] } as unknown as UnitsResponse;
      }
      if (unload_data.includes('unit') || (unload_data.includes('*') && !unload_data.includes('^unit'))) {
        this.store.unit = {} as UnitResponse;
      }
      if (unload_data.includes('parts') || (unload_data.includes('*') && !unload_data.includes('^parts'))) {
        this.store.parts = { parts: [] } as unknown as PartsResponse;
      }
      if (unload_data.includes('part') || (unload_data.includes('*') && !unload_data.includes('^part'))) {
        this.store.part = {} as PartResponse;
      }
      if (unload_data.includes('events') || (unload_data.includes('*') && !unload_data.includes('^events'))) {
        this.store.event = { events: [] } as unknown as EventsResponse;
      }
      if (unload_data.includes('ridelogs') || (unload_data.includes('*') && !unload_data.includes('^ridelogs'))) {
        this.store.ridelogs = [];
      }
      if (unload_data.includes('maintenances') || (unload_data.includes('*') && !unload_data.includes('^maintenances'))) {
        this.store.maintenances = { maintenances: [] } as unknown as MaintenancesResponse;
      }
      if (unload_data.includes('maintenance') || (unload_data.includes('*') && !unload_data.includes('^maintenance'))) {
        this.store.maintenance = {} as MaintenanceResponse;
      }
      if (unload_data.includes('reports') || (unload_data.includes('*') && !unload_data.includes('^reports'))) {
        this.store.reports = { reports: [] } as unknown as ReportsResponse;
      }
    }
}
