<clr-modal clrModalSize="lg" [clrModalOpen]="_show" (clrModalOpenChange)="_showChange(show)">

    <h3 class="modal-title">{{ 'Forms.ChangePasswordModal.Title' | translate }}</h3>
    <div class="modal-body">
        <form clrForm clrLayout="horizontal" [formGroup]="passwordForm">
            <clr-password-container>
                <label>{{ 'Forms.ChangePasswordModal.OldPassword.Label' | translate }}</label>
                <input clrPassword formControlName="old_password" />
                <clr-control-error *clrIfError="'required'">{{
                    'Forms.ChangePasswordModal.OldPassword.ErrorRequired' | translate
                    }}</clr-control-error>
                <clr-control-error *clrIfError="'wrong'">{{
                    'Forms.ChangePasswordModal.OldPassword.ErrorWrongPassword' | translate
                    }}</clr-control-error>
            </clr-password-container>

            <clr-password-container>
                <label>{{ 'Forms.ChangePasswordModal.NewPassword.Label' | translate }}</label>
                <input clrPassword formControlName="user_password" />
                <clr-control-error *clrIfError="'required'">{{
                    'Forms.ChangePasswordModal.NewPassword.ErrorRequired' | translate
                    }}</clr-control-error>
            </clr-password-container>

            <clr-password-container>
                <label>{{ 'Forms.ChangePasswordModal.NewPasswordConfirm.Label' | translate }}</label>
                <input clrPassword formControlName="confirm_password" />
                <clr-control-error *clrIfError="'required'">{{
                    'Forms.ChangePasswordModal.NewPasswordConfirm.ErrorRequired' | translate
                    }}</clr-control-error>
                <clr-control-error *clrIfError="'passwordMismatch'">{{
                    'Forms.ChangePasswordModal.NewPasswordConfirm.ErrorMismatch' | translate
                    }}</clr-control-error>
            </clr-password-container>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()">{{ 'Forms.Cancel' | translate }}</button>
        <button type="button" class="btn btn-success" (click)="onConfirm()"
            [disabled]="!passwordForm.valid">{{'Forms.Save' | translate }}</button>
    </div>

</clr-modal>