import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { SuccessConfirmation } from '../types/base';
import { EventResponse, EventRequest, EventsResponse } from '../types/event';
import { Event } from '../types/event';
@Injectable({
    providedIn: 'root',
})
export class EventHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getEvent(id: string, unit_id: string, event_id: string): Observable<EventResponse> {
        return this.http
            .get<EventResponse>('attractions/' + id + '/units/' + unit_id + '/events/' + event_id)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllEvents(id: string, unit_id: string): Observable<EventsResponse> {
        return this.http
            .get<EventsResponse>('attractions/' + id + '/units/' + unit_id + '/events/')
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createEvent(id: string, unit_id: string, event: EventRequest): Observable<EventResponse> {
        return this.http
            .post<EventResponse>('attractions/' + id + '/units/' + unit_id + '/events/', event)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    updateEvent(id: string, unit_id: string, event_id: string, event: EventRequest): Observable<EventResponse> {
        return this.http
            .put<EventResponse>('attractions/' + id + '/units/' + unit_id + '/events/' + event_id, event)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deleteEvent(id: string, unit_id: string, event_id: string): Observable<SuccessConfirmation> {
        return this.http
            .delete<SuccessConfirmation>('attractions/' + id + '/units/' + unit_id + '/events/' + event_id)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }
}
