<clr-modal clrModalSize="md" [(clrModalOpen)]="service.showNewMembers">

        <h3 class="modal-title">{{'Forms.NewMembers.Title' | translate}}</h3>
        <div class="modal-body">
            <table class="table table-noborder" *ngIf="service.newMembers">
                <tr>
                    <th>
                     <clr-checkbox-wrapper style="margin: 0">
                            <input type="checkbox" clrCheckbox (change)="service.checkAllMembers($event)" [indeterminate]="service.interdediateMembers" [formControl]="service.allCheckboxMembers"/>
                        </clr-checkbox-wrapper>
                        </th>
                    <th style="vertical-align: middle;">{{ 'Misc.Field1' | translate }}</th>
                    <th style="vertical-align: middle;">{{ 'Misc.Field2' | translate }}</th>
                </tr>
                <tr *ngFor="let member of service.newMembers; let i = index">
                     <td>
                        <clr-checkbox-wrapper style="margin: 0">
                            <input type="checkbox" clrCheckbox [formControl]="member.form" (change)="service.checkMembers($event)" />
                        </clr-checkbox-wrapper>
                     </td>

                   <td style="vertical-align: middle;">{{ member.field1 }}</td>
                   <td style="vertical-align: middle;">{{ member.field2 }}</td>
                </tr>
            </table>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-warning" (click)="applyChanges()">{{ 'Actions.Apply' | translate }}</button>
        </div>

</clr-modal>