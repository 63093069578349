<clr-modal clrModalSize="md" [(clrModalOpen)]="service.showResourceChange">
<h3 class="modal-title">{{'Forms.ResourceChange.Title' | translate}}</h3>
        <div class="modal-body">
            <table class="table table-noborder" *ngIf="service.changes">
                <tr>
                    <th>
                     <clr-checkbox-wrapper style="margin: 0">
                            <input type="checkbox" clrCheckbox (change)="service.checkAll($event)" [indeterminate]="service.interdediate" [formControl]="service.allCheckbox"/>
                        </clr-checkbox-wrapper>
                        </th>
                    <th style="vertical-align: middle;">{{ 'Misc.Key' | translate }}</th>
                    <th style="vertical-align: middle;">{{ 'Misc.OriginalValue' | translate }}</th>
                    <th style="vertical-align: middle;">{{ 'Misc.NewValue' | translate }}</th>
                </tr>
                <tr *ngFor="let change of service.alterations; let i = index">
                     <td>
                        <clr-checkbox-wrapper style="margin: 0">
                            <input type="checkbox" clrCheckbox [formControl]="change.form" (change)="service.check($event)" />
                        </clr-checkbox-wrapper>
                     </td>

                   <td style="vertical-align: middle;">{{ change.change.key }}</td>
                   <td style="vertical-align: middle; text-align: left;" *ngIf="isObject(change.change.from)">
                        <span *ngFor="let k of getKeys(change.change.from)">{{k}}: {{ getString(change.change.from, k)}}<br></span>
                   </td>
                   <td style="vertical-align: middle; text-align: left;" *ngIf="!isObject(change.change.from)">{{ change.change.from }}</td>
                   <td style="vertical-align: middle; text-align: left;" *ngIf="isObject(change.change.from)">
                        <span *ngFor="let k of getKeys(change.change.to)">{{k}}: {{ getString(change.change.to,k)}}<br></span>
                   </td>
                   <td style="vertical-align: middle; text-align: left;" *ngIf="!isObject(change.change.to)">{{ change.change.to }}</td>
                </tr>
            </table>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-warning" (click)="apply()">{{ 'Actions.Apply' | translate }}</button>
        </div>

</clr-modal>
