import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UrlService {

    constructor() { }
    private apiUrl =
        location.protocol + '//' + location.hostname + (location.port ? ':' + environment.API_PORT : '') + '/api/v1/';
    private staticUrl =
        location.protocol + '//' + location.hostname + (location.port ? ':' + environment.STATIC_PORT : '');
    private fileUrl =
        location.protocol + '//' + location.hostname + (location.port ? ':' + environment.API_PORT : '');


    getUrl(url: string): string {
        if (url.startsWith('/assets')) {
            url = this.staticUrl + url;
        } else if (url.startsWith('/static')) {
            url = this.fileUrl + url;
        } else {
            url = this.apiUrl + url;
        }
        return url

    }
}