import { Injectable } from '@angular/core';
import { SelectedFile } from 'src/app/shared/image-selector/image-selector.component';
import { FileHttpService } from '../http/file.http';
import { FileInfo, FileInfoRequest, FileInfoResponse } from '../types/file';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private fileService: FileHttpService, private utils: UtilsService) { }

  public fileQueue: { selectedFile: SelectedFile, fileInfo: FileInfoRequest, percent: number, resolve: (value: FileInfoResponse | PromiseLike<FileInfoResponse>) => void }[] = []

  add(selectedFile: SelectedFile, fileInfo: FileInfoRequest): Promise<FileInfoResponse> {
    const promise = new Promise<FileInfoResponse>((resolve, reject) => {
      if (selectedFile && fileInfo) {
        const file = { selectedFile, fileInfo, percent: 0, resolve }
        file.selectedFile.obs = this.fileService.createFile(file.selectedFile.file, file.fileInfo).subscribe((res) => {
          if (this.utils.isHttpResponse(res) && res.body) {
            file.fileInfo = res.body.file;
            this.fileQueue.splice(this.fileQueue.indexOf(file), 1)
            resolve(res.body)
          }
          if (this.utils.isHttpProgressEvent(res) && res.total) {
            file.percent = Math.round(100 * res.loaded / res.total);
          }
        })
        this.fileQueue.push(file)
      } else {
        resolve({} as FileInfoResponse)
      }

    })
    return promise
  }

  onCancel(file: { selectedFile: SelectedFile, fileInfo: FileInfoRequest, percent: number, resolve: (value: FileInfoResponse | PromiseLike<FileInfoResponse>) => void }) {
    file.selectedFile.obs?.unsubscribe()
    file.resolve({} as FileInfoResponse)
    this.fileQueue.splice(this.fileQueue.indexOf(file), 1)
  }

  cancelQueue() {
    this.fileQueue.forEach((file) => this.onCancel(file))
  }

}
