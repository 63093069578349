import { Component,  EventEmitter, Input,  Output,} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  ClrCommonFormsModule,
  ClrInputModule, ClrModalModule,
  ClrPasswordModule, ClrSelectModule,
  ClrWizardModule
} from '@clr/angular';
import { UserHttpService } from 'src/app/core/http/user.http';
import { SettingsService } from 'src/app/core/services/settings.service';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { GroupStore } from 'src/app/core/stores/group.store';
import { UserStore } from 'src/app/core/stores/user.store';
import { UserInfo, UserRequestFull } from 'src/app/core/types/user';
import {
    forbiddenUsernameValidator,
    groupTypeValidator,
    confirmPasswordValidator,
} from 'src/app/core/validators/user.validator';
import {CommonModule, NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-user-wizard',
    templateUrl: './user-wizard.component.html',
    standalone: true,
    imports: [
      CommonModule,
      ClrCommonFormsModule,
      FormsModule,
      NgForOf,
      NgIf,
      ReactiveFormsModule,
      TranslateModule,
      ClrInputModule,
      ClrSelectModule,
      ClrModalModule
    ],
    styleUrls: ['./user-wizard.component.scss'],
})
export class UserWizardComponent {

    public _show: boolean = false;
    public _showChange(show: boolean): void {
        this._show = show
        this.showChange.emit(this._show)
    }

    @Input()
    set show(open: boolean) {
      this._show = open
      if (this._userInfo?.id !== undefined && open) {
        this.fillForm(this._userInfo)
      }

    }

    public _userInfo: UserInfo | undefined = undefined;
    @Input()
    set user(user: UserInfo | undefined) {
      this._userInfo = undefined
      if (user && user.id != undefined) {
        this._userInfo = user;
        this.fillForm(user)

      }
    }

    @Output() showChange = new EventEmitter<boolean>();
    @Output() completed = new EventEmitter<boolean>();


    public userForm = new UntypedFormGroup({
        user_first_name: new UntypedFormControl('', [Validators.required]),
        user_last_name: new UntypedFormControl('', [Validators.required,]),
        user_mail: new UntypedFormControl('', [Validators.required, Validators.email, forbiddenUsernameValidator()]),
    });

    public groupTypeForm = new UntypedFormGroup(
        {
            user_group: new UntypedFormControl(null, Validators.required),
            user_type: new UntypedFormControl(null, Validators.required),
        },
        { validators: groupTypeValidator(this.groupStore) }
    );

  fillForm(user: UserInfo) {
    this.userForm.patchValue({
      user_first_name: user.user_first_name,
      user_last_name: user.user_last_name,
      user_mail: user.user_mail,
    })
    this.groupTypeForm.patchValue({
      user_group: user.parent_id,
      user_type: user.user_type,
    })
  }

    constructor(
        private userService: UserHttpService,
        public authStore: AuthStore,
        public userStore: UserStore,
        public groupStore: GroupStore,
        public settings: SettingsService
    ) { }

    onCancel() {
      this.userForm.reset();
      this.groupTypeForm.reset()
      this.showChange.emit(this.show);
      this._showChange(false)
      this.completed.emit(true);
    }

    onComplete() {
        const user: UserRequestFull = {
            user_first_name: this.userForm.value.user_first_name,
            user_last_name: this.userForm.value.user_last_name,
            user_type: parseInt(this.groupTypeForm.getRawValue().user_type),
            user_mail: this.userForm.value.user_mail,
            parent_id: this.groupTypeForm.getRawValue().user_group,
        };

        if (!this._userInfo) {
          this.userService.createUser(user).subscribe((res) => {
            this.userStore.addUser(res.user);
            this.onCancel();
          });
        } else {
          this.userService.updateUser(this._userInfo.id, user).subscribe((res) => {
            this.userStore.updateUser(res.user);
            this.onCancel();
          });
        }
    }

}
