import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
      enableProdMode();
      Sentry.init({
      dsn: "https://66f9b03f22ccc90b3f72068038c0b85d@o4503974940835840.ingest.sentry.io/4505788318351360",
      integrations: [
       new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.routingInstrumentation,
       }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      enableTracing: true,
      environment: environment.production ? "production" : "development",
      release: "maintenance-app-web@"+environment.VERSION,
        autoSessionTracking: true,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/maintenance.maurer-rides\.de\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
