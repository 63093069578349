import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MaintenanceService } from 'src/app/core/services/maintenance.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-maintenance-header',
  templateUrl: './maintenance-header.component.html',
  styleUrls: ['./maintenance-header.component.scss']
})
export class MaintenanceHeaderComponent {

  constructor(public maintenanceService: MaintenanceService, private translate: TranslateService, private utils: UtilsService) { }

  clearMaintenanceService() {
    this.maintenanceService.clear();
  }

  getMaintenanceDescription(): string {
    return `${this.maintenanceService.attraction?.attraction.attraction_name} → ${this.maintenanceService.unit?.full_code_letter} → ${this.utils.getI18nString(this.maintenanceService.part?.part.group, this.translate.currentLang)} → ${this.utils.getI18nString(this.maintenanceService.part?.part.name, this.translate.currentLang)}`
  }

}
