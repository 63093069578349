import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { catchError, scan } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { SuccessConfirmation } from '../types/base';
import { FileInfo, FileInfoRequest, FileInfoResponse, FileInfosResponse } from '../types/file';

const uploadOptions = { headers: new HttpHeaders(), observe: 'events' as const, reportProgress: true };


@Injectable({
    providedIn: 'root',
})
export class FileHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getFileInfo(id: string): Observable<FileInfoResponse> {
        return this.http.get<FileInfoResponse>('files/' + id).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllFileInfos(parent_id: string): Observable<FileInfosResponse> {
        return this.http.get<FileInfosResponse>('files/parent/' + parent_id).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createFile(file: File, info: FileInfoRequest): Observable<HttpEvent<FileInfoResponse>> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', info.file_name);
        formData.append('desciption', info.file_description);
        formData.append('parent', info.parent_id);
        formData.append('index', info.index.toString());
        formData.append('group', info.group.toString());
        return <Observable<HttpEvent<FileInfoResponse>>>this.http.post<FileInfoResponse>('files/', formData, uploadOptions).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    updateFileInfo(id: string, file: FileInfoRequest): Observable<FileInfoResponse> {
        return this.http.put<FileInfoResponse>('files/' + id, file).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deleteFile(id: string): Observable<SuccessConfirmation> {
        return this.http.delete<SuccessConfirmation>('files/' + id).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }


}
