import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Attraction, AttractionResponse, AttractionsResponse } from "../types/attraction";
import { AttractionHttpService } from "../http/attraction.http";
import { DashboardStore } from "../stores/dashboardStore";
import {GroupHttpService} from "../http/group.http";
import {GroupStore} from "../stores/group.store";
import {Group, GroupResponse} from "../types/group";

@Injectable({ providedIn: 'root' })
export class GroupResolver  {
    constructor(private http: GroupHttpService, private store: GroupStore) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<GroupResponse[]> | Promise<GroupResponse[]> | GroupResponse[] {
        if (this.store.groups?.length > 0) {
          return this.store.groups;
        }
        return new Promise((resolve, reject) => {
            // get data from route config

            this.http.getAllGroups().subscribe((response) => {
                this.store.groups = response.groups
                resolve(response.groups);
            }, (error) => {
                reject(error);
            });
        });
    }
}
