<clr-modal clrModalSize="md" [(clrModalOpen)]="service.showResourceInfo">

        <h3 class="modal-title">{{'Forms.ResourceInfo.Title' | translate}}</h3>
        <div class="modal-body">
            <table class="table table-noborder">
                <tr *ngIf="service.changes.at(0)">
                    <td class="left">{{ 'Misc.CreatedBy' | translate }}:</td>
                    <td class="left" *ngIf="service.changes.at(0).user_name"><strong>
                            <cds-icon shape="user" badge="success"> </cds-icon> {{ service.changes.at(0).user_name }}
                        </strong></td>
                    <td class="left" *ngIf="!service.changes.at(0).user_name"><strong>
                            <cds-icon shape="host" badge="success"> </cds-icon> Server
                        </strong></td>
                    <td class="left" *ngIf="service.changes.at(0).time">
                        {{ 'Misc.At' | translate }} {{ utils.toDateString(service.changes.at(0).time) }}
                    </td>
                </tr>
                <tr *ngIf="service.changes.at(-1)">
                    <td class="left">{{ 'Misc.LastChange' | translate }}:</td>
                    <td class="left" *ngIf="service.changes.at(-1).user_name"><strong>
                            <cds-icon shape="user" badge="info"> </cds-icon> {{ service.changes.at(-1).user_name }}
                        </strong></td>
                    <td class="left" *ngIf="!service.changes.at(-1).user_name"><strong>
                            <cds-icon shape="host" badge="success"> </cds-icon> Server
                        </strong></td>
                    <td class="left" *ngIf="service.changes.at(-1).time">
                        {{ 'Misc.At' | translate }} {{ utils.toDateString(service.changes.at(-1).time) }}
                    </td>
                </tr>
            </table>
        </div>

</clr-modal>
