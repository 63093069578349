import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {Attraction, AttractionResponse, AttractionsResponse, WordmarkList} from '../types/attraction';
import {Event, EventsResponse, EventTypeIBN, EventTypeInOut} from '../types/event';
import { Maintenance, MaintenanceResponse, MaintenancesResponse } from '../types/maintenance';
import { Part, PartResponse, PartsResponse } from '../types/part';
import {Report, ReportsResponse} from '../types/report';
import { Ridelog } from '../types/ridelog';
import { Unit, UnitResponse, UnitsResponse } from '../types/unit';
@Injectable({
    providedIn: 'root',
})
export class DashboardStore {

    constructor() { }

    // Attraction: Getter and Setter
    private attractionSubject: BehaviorSubject<AttractionResponse> = new BehaviorSubject<AttractionResponse>({ attraction: {} as Attraction } as AttractionResponse)
    get attraction(): AttractionResponse { return this.attractionSubject.value }
    get $attraction(): Observable<AttractionResponse> { return new Observable((fn) => this.attractionSubject.subscribe(fn)) }
    set attraction(attraction: AttractionResponse) { this.attractionSubject.next(attraction); }

    // Attractions: Getter and Setter
    private attractionsSubject: BehaviorSubject<AttractionsResponse> = new BehaviorSubject<AttractionsResponse>({ attractions: [] } as unknown as AttractionsResponse)
    get attractions(): AttractionsResponse { return this.attractionsSubject.value }
    get $attractions(): Observable<AttractionsResponse> { return new Observable((fn) => this.attractionsSubject.subscribe(fn)) }
    set attractions(attractions: AttractionsResponse) { this.attractionsSubject.next(attractions) }

    // Unit: Getter and Setter
    private unitSubject: BehaviorSubject<UnitResponse> = new BehaviorSubject<UnitResponse>({ unit: {} as Unit } as UnitResponse)
    get unit(): UnitResponse { return this.unitSubject.value }
    get $unit(): Observable<UnitResponse> { return new Observable((fn) => this.unitSubject.subscribe(fn)) }
    set unit(unit: UnitResponse) { this.unitSubject.next(unit) }

    // Units: Getter and Setter
    private unitsSubject: BehaviorSubject<UnitsResponse> = new BehaviorSubject<UnitsResponse>({ units: [] } as unknown as UnitsResponse)
    get units(): UnitsResponse { return this.unitsSubject.value }
    get $units(): Observable<UnitsResponse> { return new Observable((fn) => this.unitsSubject.subscribe(fn)) }
    set units(units: UnitsResponse) {
        this.unitsSubject.next(units);
    }
    getUnit(id: string): UnitResponse | undefined {
        const arr = this.unitsSubject.value.units.filter((g) => g.unit.id === id);
        if (arr.length === 1) { return arr[0] } else { return undefined; }
    }
    getUnitByCodeLetter(full_code_letter: string): UnitResponse | undefined {
        const arr = this.unitsSubject.value.units.filter((g) => g.full_code_letter === full_code_letter);
        if (arr.length === 1) { return arr[0] } else { return undefined; }
    }

    // Part: Getter and Setter
    private partSubject: BehaviorSubject<PartResponse> = new BehaviorSubject<PartResponse>({ part: {} as Part } as PartResponse)
    get part(): PartResponse { return this.partSubject.value }
    get $part(): Observable<PartResponse> { return new Observable((fn) => this.partSubject.subscribe(fn)) }
    set part(part: PartResponse) { this.partSubject.next(part) }

    // Parts: Getter and Setter
    private partsSubject: BehaviorSubject<PartsResponse> = new BehaviorSubject<PartsResponse>({ parts: [] } as unknown as PartsResponse)
    get parts(): PartsResponse { return this.partsSubject.value }
    get $parts(): Observable<PartsResponse> { return new Observable((fn) => this.partsSubject.subscribe(fn)) }
    set parts(parts: PartsResponse) {
        this.partsSubject.next(parts)
    }

    getPartByNumber(n: string): PartResponse | undefined {
        let arr = n.split(':')
        return this.parts.parts.find((p) => p.part.number === arr[1] && (p.part.group.de === arr[0] || p.part.group.en === arr[0]))
    }

    // Maintenance: Getter and Setter    get maintenance(): Maintenance { return this.maintenanceSubject.value }
    private maintenanceSubject: BehaviorSubject<MaintenanceResponse> = new BehaviorSubject<MaintenanceResponse>({ maintenance: {} as Maintenance } as MaintenanceResponse)
    get $maintenance(): Observable<MaintenanceResponse> { return new Observable((fn) => this.maintenanceSubject.subscribe(fn)) }
    get maintenance(): MaintenanceResponse { return this.maintenanceSubject.value }
    set maintenance(maintenance: MaintenanceResponse) { this.maintenanceSubject.next(maintenance) }

    // Maintenances: Getter and Setter
    private maintenancesSubject: BehaviorSubject<MaintenancesResponse> = new BehaviorSubject<MaintenancesResponse>({ maintenances: [] } as unknown as MaintenancesResponse)
    get maintenances(): MaintenancesResponse { return this.maintenancesSubject.value }
    get $maintenances(): Observable<MaintenancesResponse> { return new Observable((fn) => this.maintenancesSubject.subscribe(fn)) }
    set maintenances(maintenances: MaintenancesResponse) { this.maintenancesSubject.next(maintenances) }

    // Event: Getter and Setter
    private eventSubject: BehaviorSubject<EventsResponse> = new BehaviorSubject<EventsResponse>({ events: [] } as unknown as EventsResponse)
    get event(): EventsResponse { return this.eventSubject.value }
    set event(events: EventsResponse) { this.eventSubject.next(events) }
    get $event(): Observable<EventsResponse> { return new Observable((fn) => this.eventSubject.subscribe(fn)) }

    // Ridelogs: Getter and Setter
    private ridelogsSubject: BehaviorSubject<Ridelog[]> = new BehaviorSubject<Ridelog[]>([])
    get ridelogs(): Ridelog[] { return this.ridelogsSubject.value }
    set ridelogs(ridelogs: Ridelog[]) { this.ridelogsSubject.next(ridelogs) }
    get $ridelogs(): Observable<Ridelog[]> { return new Observable((fn) => this.ridelogsSubject.subscribe(fn)) }

    // Reports: Getter and Setter
    private reportsSubject: BehaviorSubject<ReportsResponse> = new BehaviorSubject<ReportsResponse>({ reports: [] } as unknown as ReportsResponse)
    get reports(): ReportsResponse { return this.reportsSubject.value }
    set reports(reports: ReportsResponse) { this.reportsSubject.next(reports) }
    get $reports(): Observable<ReportsResponse> { return new Observable((fn) => this.reportsSubject.subscribe(fn)) }

    // Helpers
    hasIBN() {
        return this.event.events.filter((e) => e.event.event_type === EventTypeIBN).length === 0 ? false : true;
    }

    isOffline() {
        return this.event.events.filter((e) => e.event.event_type === EventTypeInOut).length % 2 == 0
    }

    getNameById(id: string): string {
        const arr = this.attractionsSubject.value.attractions.filter((g) => g.attraction.id === id); if (arr.length === 1) { return arr[0].attraction.attraction_name; } else { return 'Errors.NoAttractionFound'; }
    }

    getIdByName(name: string): string {
        const arr = this.attractionsSubject.value.attractions.filter((g) => g.attraction.attraction_name === name); if (arr.length === 1) { return arr[0].attraction.id; } else { return ''; }
    }
}
