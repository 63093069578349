import { Alteration, Base } from './base';
import { i18nString } from './i18nString';
import {PartResponse} from "./part";
import {UnitResponse} from "./unit";

export interface Maintenance extends Base {
    singular: boolean;
    description: i18nString;
    item_numbers: string[];
    criterion: i18nString[];
    findings: i18nString[];
    measures: i18nString[];
    methods: Constant[];
    sequence: number;
    number: number;
    triggers: Constant[];
    custom: boolean;
    review: boolean;

}
export interface Deadline {
    last_completion: string;
    last_completion_id: string;
    last_completion_type: string;
    next_completion: string;
    success_level: number;
    success_count: number;
    current_date: string;
}

export type MaintenanceRequest = Omit<Maintenance, keyof Base>;
export interface MaintenanceResponse {
    maintenance: Maintenance;
    deadline: Deadline;
    has_changes: boolean;
    changes: Alteration[];
    manual_page: number;
}

export interface MaintenanceListResponse {
  maintenances: MaintenanceResponse[];
  units: UnitResponse[];
  parts: PartResponse[];
}

export interface MaintenancesResponse {
    maintenances: MaintenanceResponse[];
    has_new_members: boolean;
    new_maintenances: Maintenance[];
}

export interface Constant {
    id: string,
    type: string
}

export const ConstantIntervallDaily: Constant = { id: 'T', type: 'intervall' };
export const ConstantIntervallWeekly: Constant = { id: 'W', type: 'intervall' };
export const ConstantIntervallMonthly: Constant = { id: 'M', type: 'intervall' };
export const ConstantIntervallYearly: Constant = { id: 'J', type: 'intervall' };
export const ConstantIntervall2Monthly: Constant = { id: '2M', type: 'intervall' };
export const ConstantIntervall3Monthly: Constant = { id: '3M', type: 'intervall' };
export const ConstantIntervall4Monthly: Constant = { id: '4M', type: 'intervall' };
export const ConstantIntervall6Monthly: Constant = { id: '6M', type: 'intervall' };
export const ConstantIntervallTBJ: Constant = { id: 'TBJ', type: 'intervall' };
export const ConstantIntervallNDT: Constant = { id: 'NDT', type: 'intervall' };
export const ConstantIntervallSplit12: Constant = { id: '1/12', type: 'intervall' };
export const ConstantIntervallSplit10: Constant = { id: '1/10', type: 'intervall' };
export const ConstantEventInOut: Constant = { id: 'IO', type: 'event' };
export const ConstantEventChangeWheel: Constant = { id: 'CW', type: 'event' };
export const ConstantEventAdjustWheel: Constant = { id: 'AW', type: 'event' };
export const ConstantEventDriveRoll: Constant = { id: 'CD', type: 'event' };
export const ConstantEventSpecialUnits: Constant = { id: 'SU', type: 'event' };
export const IntervallArray = [
    ConstantIntervallDaily,
    ConstantIntervallWeekly,
    ConstantIntervallMonthly,
    ConstantIntervallYearly,
    ConstantIntervall2Monthly,
    ConstantIntervall3Monthly,
    ConstantIntervall4Monthly,
    ConstantIntervall6Monthly,
    ConstantIntervallTBJ,
    ConstantIntervallNDT,
    ConstantIntervallSplit12,
    ConstantEventInOut,
    ConstantEventChangeWheel,
    ConstantEventAdjustWheel,
    ConstantEventDriveRoll,
    ConstantEventSpecialUnits,
];

export const TestMethodAcoustic: Constant = { id: 'Akus', type: "test" }
export const TestMethodTorque: Constant = { id: 'Dreh', type: "test" }
export const TestMethodManuell: Constant = { id: 'Manu', type: "test" }
export const TestMethodMeasure: Constant = { id: 'Mess', type: "test" }
export const TestMethodShake: Constant = { id: 'Rütt', type: "test" }
export const TestMethodVisuell: Constant = { id: 'Sich', type: "test" }
export const TestMethodNDT: Constant = { id: 'NDT', type: "test" }
export const TestMethodNone: Constant = { id: '-', type: "test" }
export const TestMethodArray = [
    TestMethodAcoustic,
    TestMethodTorque,
    TestMethodManuell,
    TestMethodMeasure,
    TestMethodShake,
    TestMethodVisuell,
    TestMethodNDT,
    TestMethodNone
]
