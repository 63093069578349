<div class="alert alert-app-level alert-danger" role="alert" *ngIf="error.currentError" style="z-index: 10000;">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
            </div>
            <div class="alert-text">
                {{'Misc.SomethingWentWrong' | translate}}
            </div>
            <div class="alert-actions">
                <button class="btn alert-action" (click)="error.openDetails()"
                    *ngIf="!error.showDetails">{{'Actions.ShowInfo' |
                    translate}}</button>
                <button class="btn alert-action" (click)="error.closeDetails()"
                    *ngIf="error.showDetails">{{'Actions.CloseInfo' |
                    translate}}</button>
            </div>
        </div>

        <div class="alert-item static" *ngIf="error.showDetails">
            <div class="alert-icon-wrapper">

            </div>
            <div class="alert-text">
                {{error.currentError.message}}<br>
                <code *ngIf="!error.currentError.error.error">{{error.currentError.error}}</code>
                <code
                    *ngIf="error.currentError.error.error"><span *ngFor="let e of error.currentError.error.error">{{e}}<br></span></code>
            </div>
        </div>
    </div>


    <button type="button" class="close" aria-label="Close" (click)="error.clearError()">
        <cds-icon aria-hidden="true" shape="close"></cds-icon>
    </button>
</div>