import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DashboardStore } from 'src/app/core/stores/dashboardStore';
import { Attraction } from 'src/app/core/types/attraction';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  constructor(private router: Router, public dashboardStore: DashboardStore, public utils: UtilsService, public translate: TranslateService) { }
  public currentUrl: string = ""

  public currentPath: { url: string, index: number, key?: string, text?: string }[] = [];
  public isPDF: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth < 768) {
      this.buildPath(true);
    } else {
      this.buildPath(false);
    }
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let navigationStart = event as NavigationEnd
        this.currentUrl = navigationStart.urlAfterRedirects
        if (this.currentUrl.includes('pdf')) {
          this.isPDF = true;
          return
        }
        if (window.innerWidth < 768) {
          this.buildPath(true)
        } else {
          this.buildPath(false)
        }
      }
    })
  }

  buildPath(smallScreen: boolean) {
    this.currentPath = [];
    if (this.currentUrl.includes('admin')) {
      this.currentPath.push({
        key: 'Header.GlobalManagement',
        index: 0,
        url: ''
      })
      if (this.currentUrl.includes('admin/users')) {
        this.currentPath.push({
          key: 'Header.UserManagement',
          index: 1,
          url: '/admin/users'
        })
      }
      if (this.currentUrl.includes('admin/groups')) {
        this.currentPath.push({
          key: 'Header.GroupManagement',
          index: 1,
          url: '/admin/groups'
        })
      }
    }

    if (this.currentUrl.includes('data')) {
      this.currentPath.push({
        key: 'Header.Dashboard',
        index: 0,
        url: '/dashboard'
      })
    }

    if (this.currentUrl.includes('attractions')) {
      this.currentPath.push({
        key: 'Header.Attractions',
        index: 0,
        url: '/attractions'
      })
    }

    if (this.currentUrl.includes('/attractions/')) {
      this.currentPath.push({
        text: this.dashboardStore.attraction.attraction.attraction_name,
        index: 1,
        url: '/attractions/' + this.dashboardStore.attraction.attraction.id
      })
    }

    if (this.currentUrl.includes('/ridelogs')) {
      this.currentPath.push({
        key: 'Header.Ridelog',
        index: 4,
        url: ''
      })
    }


    if (this.currentUrl.includes('/units/') && this.dashboardStore.unit) {
      this.currentPath.push({
        text: this.dashboardStore.unit.full_code_letter,
        index: 2,
        url: '/attractions/' + this.dashboardStore.attraction.attraction.id + '/units/' + this.dashboardStore.unit.unit.id
      })
    }

    if (this.currentUrl.includes('/parts/') && this.dashboardStore.unit) {
      this.currentPath.push({
        text: this.utils.getI18nString(this.dashboardStore.part.part.name, this.translate.currentLang),
        index: 3,
        url: '/attractions/' + this.dashboardStore.attraction.attraction.id + '/units/' + this.dashboardStore.unit.unit.id + '/parts/' + this.dashboardStore.part.part.id
      })
    }

    if (this.currentUrl.includes('/maintenances/') && this.dashboardStore.unit) {
      this.currentPath.push(
        {
          text: this.utils.getI18nString(this.utils.i18nJoin(this.dashboardStore.maintenance.maintenance.criterion, ','), this.translate.currentLang),
          index: 3,
          url: '/attractions/' + this.dashboardStore.attraction.attraction.id + '/units/' + this.dashboardStore.unit.unit.id + '/parts/' + this.dashboardStore.part.part.id + '/maintenances/' + this.dashboardStore.maintenance.maintenance.id
        })
    }


    if (this.currentUrl.includes('/attractions/files')) {
      this.currentPath = [{
          key: 'Header.Files',
          index: 0,
          url: ''
        }]
    }

    if (this.currentUrl.includes('/attractions/trash')) {
      this.currentPath = [{
        key: 'Header.Trash',
        index: 0,
        url: ''
      }]
    }

    if (smallScreen) {
      this.currentPath = this.currentPath.slice(-1)
    }
  }
}
