import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import {
  AuthChangePasswordResponseBody,
  AuthLogoutResponseBody, AuthLogResponse,
  LoginRequest,
  PasswordChangeRequest,
} from '../types/auth';
import { SuccessConfirmation } from '../types/base';
import { UserResponse } from '../types/user';

@Injectable({
    providedIn: 'root',
})
export class AuthHttpService {
    constructor(private http: HttpClient, private router: Router, private errorService: ErrorService) { }

    getCurrent(): Observable<UserResponse | string> {
        return this.http.get<UserResponse | string>('auth/current');
    }

    getLogout(): Observable<SuccessConfirmation> {
        return this.http.get<AuthLogoutResponseBody>('auth/logout');
    }

    getLogin(body: LoginRequest): Observable<UserResponse> {
        return this.http.put<UserResponse>('auth/login', body);
    }

    putPasswordChange(body: PasswordChangeRequest): Observable<SuccessConfirmation> {
        return this.http
            .put<AuthChangePasswordResponseBody>('auth/change', body)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    activate(token: string, password: string) {
        return this.http
            .post<AuthChangePasswordResponseBody>('auth/activate', {token: token, user_password: password})
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    reset(user_name: string) {
        return this.http
            .post<AuthChangePasswordResponseBody>('auth/reset', {user_mail: user_name})
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAuthLogForUser(user_id: string): Observable<AuthLogResponse> {
        return this.http.get<AuthLogResponse>('auth/log/'+ user_id);
    }

    getAuthLog(): Observable<AuthLogResponse> {
        return this.http.get<AuthLogResponse>('auth/log');
    }
}
