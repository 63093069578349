import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileHttpService } from 'src/app/core/http/file.http';
import { ImageDisplayService } from 'src/app/core/services/image-display.service';
import { UrlService } from 'src/app/core/services/url.service';

@Component({
  selector: 'app-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss']
})
export class ImageContainerComponent {

  public open: boolean = false;
  public images: string[] = []
  @Input()
  set src(src: string | string[] | undefined) {
    if (typeof src === 'string') {
      if (src !== '') {
        this.images = [src]
      }
    } else {
      if (src) {
        this.images = src.filter(e => e !== '')
      }
    }
  }
  @Input() group: number = 0;

  @Input()
  set parent(parent: string | undefined) {
    if (parent) {
      this.fileService.getAllFileInfos(parent).subscribe((res) => {
        this.images = res.files.filter((i) => i.file.group === this.group).map((i) => this.urlService.getUrl('/static/' + i.file.id))
      })
    }
  }


  @Input() icon: boolean = false;
  constructor(public service: ImageDisplayService, private fileService: FileHttpService, private urlService: UrlService) {

  }


}
