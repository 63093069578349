<clr-modal clrModalSize="lg" [clrModalOpen]="_show" (clrModalOpenChange)="_showChange($event)">
    <h3 class="modal-title"  *ngIf="!_userInfo">{{ 'Forms.CreateUser.Title' | translate }}</h3>
    <h3 class="modal-title"  *ngIf="_userInfo">{{ 'Forms.CreateUser.TitleEdit' | translate }}</h3>

    <div class="modal-body">
        <form clrForm clrLayout="horizontal" [formGroup]="groupTypeForm">
              <clr-select-container>
                  <label>{{ 'Forms.CreateUser.Page1.RoleSelect.Label' | translate }}</label>
                  <select clrSelect formControlName="user_type">
                      <option [value]="null" disabled selected hidden>
                          {{ 'Forms.CreateUser.Page1.RoleSelect.Default' | translate }}
                      </option>
                      <option [value]="1">{{ 'Constants.UserRoles.View' | translate }}</option>
                      <option [value]="2">{{ 'Constants.UserRoles.Edit' | translate }}</option>
                      <option [value]="3">{{ 'Constants.UserRoles.Manage' | translate }}</option>
                      <option [value]="4" *ngIf="authStore.userInfo.user_type === 4">
                          {{ 'Constants.UserRoles.Admin' | translate }}
                      </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">{{
                      'Forms.CreateUser.Page1.RoleSelect.ErrorRequired' | translate
                      }}</clr-control-error>
              </clr-select-container>

              <clr-select-container>
                  <label>{{ 'Forms.CreateUser.Page1.GroupSelect.Label' | translate }}</label>
                  <select clrSelect formControlName="user_group">
                      <option [value]="null" disabled selected hidden>
                          {{ 'Forms.CreateUser.Page1.GroupSelect.Default' | translate }}
                      </option>
                      <option *ngFor="let group of (groupStore.$groups | async)" [value]="group.group.id">
                          {{ group.group.group_name }}
                      </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">{{
                      'Forms.CreateUser.Page1.GroupSelect.ErrorRequired' | translate
                      }}</clr-control-error>
                  <clr-control-error *clrIfError="'adminGroupNeeded'">{{
                      'Forms.CreateUser.Page1.GroupSelect.ErrorAdminNeeded' | translate }}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'groupNotAllowed'">{{
                      'Forms.CreateUser.Page1.GroupSelect.ErrorAdminNotAllowed' | translate }}
                  </clr-control-error>
              </clr-select-container>
        </form>

        <form clrForm clrLayout="horizontal">
            <div [formGroup]="userForm">
                <clr-input-container>
                    <label>{{ 'Forms.CreateUser.Page2.Username.LabelFirst' | translate }}</label>
                    <input clrInput formControlName="user_first_name" />
                    <clr-control-error *clrIfError="'required'">{{ 'Forms.CreateUser.Page2.Username.ErrorRequired' |
                        translate }}
                    </clr-control-error>

                </clr-input-container>
                <clr-input-container>
                    <label>{{ 'Forms.CreateUser.Page2.Username.LabelLast' | translate }}</label>
                    <input clrInput formControlName="user_last_name" />
                    <clr-control-error *clrIfError="'required'">{{ 'Forms.CreateUser.Page2.Username.ErrorRequired' |
                        translate }}
                    </clr-control-error>
                </clr-input-container>

                <clr-input-container>
                    <label>{{ 'Forms.CreateUser.Page2.Email.Label' | translate }}</label>
                    <input clrInput formControlName="user_mail" />
                    <clr-control-error *clrIfError="'required'">{{ 'Forms.CreateUser.Page2.Email.ErrorRequired' |
                        translate }}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'email'">{{ 'Forms.CreateUser.Page2.Email.ErrorMalformed' |
                        translate }}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'forbiddenName'">{{
                        'Forms.CreateUser.Page2.Username.ErrorNotAllowed' | translate }}
                    </clr-control-error>
                </clr-input-container>
            </div>
        </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="onCancel()">{{ 'Forms.Cancel' | translate }}</button>
      <button type="button" class="btn btn-success" (click)="onComplete()" [disabled]="!userForm.valid || !groupTypeForm.valid" *ngIf="!_userInfo">
        {{ 'Forms.Add' | translate }}
      </button>
      <button type="button" class="btn btn-success" (click)="onComplete()" [disabled]="!userForm.valid || !groupTypeForm.valid" *ngIf="_userInfo">
        {{ 'Forms.Save' | translate }}
      </button>
    </div>
</clr-modal>
