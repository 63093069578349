import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploadService } from 'src/app/core/services/file-upload.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {

  constructor(public service: FileUploadService) { }

  onCancel() {
    this.service.cancelQueue()
  }

  getFileSize(size: number): string {
    let mb = size / 1024 / 1024
    return mb.toFixed(2)
  }
}

