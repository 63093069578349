import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from '../types/user';
@Injectable({
    providedIn: 'root',
})
export class UserStore {
    private userSubject: BehaviorSubject<UserInfo[]> = new BehaviorSubject<UserInfo[]>([]);

    constructor() { }

    get users(): UserInfo[] {
        return this.userSubject.value;
    }

    get $users(): Observable<UserInfo[]> {
        return new Observable((fn) => this.userSubject.subscribe(fn));
    }

    set users(users: UserInfo[]) {
        this.userSubject.next(users);
    }

    updateUser(user: UserInfo) {
        this.userSubject.next(this.userSubject.value.map((g) => (g.id === user.id ? user : g)));
    }

    deleteUser(user: UserInfo) {
        this.userSubject.next(this.userSubject.value.filter((g) => g.id !== user.id));
    }

    addUser(user: UserInfo) {
        this.userSubject.next(this.userSubject.value.concat(user));
    }
}
