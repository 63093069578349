import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { ApplyRequest, SuccessConfirmation } from '../types/base';
import { Part, PartResponse, PartRequest, PartsResponse } from '../types/part';

@Injectable({
    providedIn: 'root',
})
export class PartHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getPart(id: string, unit_id: string, part_id: string, changes: boolean = false): Observable<PartResponse> {
        let params = new HttpParams().append('changes', changes);
        return this.http
            .get<PartResponse>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id, {params})
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllParts(id: string, unit_id: string, changes: boolean = false): Observable<PartsResponse> {
        let params = new HttpParams().append('changes', changes);
        return this.http
            .get<PartsResponse>('attractions/' + id + '/units/' + unit_id + '/parts/', {params})
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createPart(id: string, unit_id: string, part: PartRequest, templateId: string): Observable<PartResponse> {

        if (templateId && templateId !== '' && templateId !== 'empty') {
            let params = new HttpParams().append('template', templateId);
            return this.http
                .post<PartResponse>('attractions/' + id + '/units/' + unit_id + '/parts/', part, { params: params })
                .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
        } else {
            return this.http
                .post<PartResponse>('attractions/' + id + '/units/' + unit_id + '/parts/', part)
                .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
        }

    }

    updatePart(
        id: string, unit_id: string,
        part_id: string,
        part: PartRequest
    ): Observable<PartResponse> {
        return this.http
            .put<PartResponse>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id, part)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    applyPart(attraction_id: string, unit_id: string, id: string, apply: ApplyRequest): Observable<SuccessConfirmation> {
        return this.http
            .put<SuccessConfirmation>('attractions/' + attraction_id + '/units/' + unit_id + '/parts/' + id + '/apply', apply)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deletePart(id: string, unit_id: string, part_id: string): Observable<SuccessConfirmation> {
        return this.http
            .delete<SuccessConfirmation>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }
}
