import { Base } from './base';


export interface Event extends Base {
    event_time: string;
    event_type: string;
    event_description: string;
}

export type EventRequest = Omit<Event, keyof Base>;
export interface EventResponse {
    event: Event;
}

export interface EventsResponse {
    events: EventResponse[];
}

export const EventTypeIBN = 'IBN';
export const EventTypeComment = 'C';
export const EventTypeFault = 'F';
export const EventTypeInOut = 'IO';
export const EventTypeChangeWheel = 'CW';
export const EventTypeAdjustWheel = 'AW';
export const EventTypeChangeDriveRoll = 'CD';
export const EventTypeSpecialUnit = 'SU';

export const EventTypeArray = [
    EventTypeIBN,
    EventTypeComment,
    EventTypeFault,
    EventTypeInOut,
    EventTypeChangeWheel,
    EventTypeAdjustWheel,
    EventTypeChangeDriveRoll,
    EventTypeSpecialUnit,
];
