<div class="alert alert-app-level alert-success" role="alert" *ngIf="maintenanceService.displayHeader">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <cds-icon class="alert-icon" shape="wrench"></cds-icon>
            </div>
            <div class="alert-text">
                {{'Header.RunningMaintenance' | translate}}: {{getMaintenanceDescription()}}
            </div>
            <div class="alert-actions">
                <button class="btn alert-action" (click)="maintenanceService.wizard?.open()">{{'Actions.Continue'
                    |translate}}</button>
            </div>
        </div>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="clearMaintenanceService()">
        <cds-icon aria-hidden="true" shape="close"></cds-icon>
    </button>
</div>