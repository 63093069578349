import { Component, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InfoModalService } from 'src/app/core/services/info-modal.service';
import { UtilsService } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-change-info-modal',
  templateUrl: './change-info-modal.component.html',
  styleUrls: ['./change-info-modal.component.scss']
})
export class ChangeInfoModalComponent {
  constructor(public service: InfoModalService, public utils: UtilsService, private router: Router) { }


  getString(obj: any, key: string): string {
    let val = obj[key];
    if (typeof val === 'string') {
      return val as string;
    }
    if (typeof val === 'object') {
      return JSON.stringify(val);
    }
    return '';
  }

  isObject(obj: any) {
    return typeof obj === 'object';
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  apply() {

    this.service.applyChanges().then((res) => {
      this.router.navigateByUrl(this.router.url);
    })
  }

}
