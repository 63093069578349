<clr-main-container #mainContainer>
  <app-error-display></app-error-display>
  <clr-header>
    <div class="branding rm-pr-mobile">
      <a routerLink="dashboard" class="nav-link">
        <img src="../assets/logos/MaurerRidesLogo-weiss.svg" class="white-logo mobile-hidden"
             *ngIf="!mainContainer.classList.contains('open-hamburger-menu')"/>
        <img src="../assets/logos/MaurerRidesLogo-schwarz.svg" class="black-logo"
             *ngIf="mainContainer.classList.contains('open-hamburger-menu')"/>
        <img *ngIf="!mainContainer.classList.contains('open-hamburger-menu')"
             src="../assets/logos/MaurerRidesLogo-min.svg" class="black-logo mobile-shown"/>
        <span class="title mobile-shown">MaintenanceApp</span>
      </a>
    </div>

    <app-breadcrumbs class="mobile-hidden" *ngIf="(auth.$userInfo | async)?.id !== ''"></app-breadcrumbs>

    <div class="header-actions">

      <a class="nav-link nav-icon-text mobile-hidden" *ngIf="settings.edit && authStore.isUser(4)"
         (click)="settings.edit = false">
        <cds-icon shape="pencil" style="color: orangered; margin-left: 0"></cds-icon>
        <span class="nav-text mobile-hidden">Edit Mode</span>
      </a>
      <a class="nav-link nav-icon-text mobile-hidden" *ngIf="!settings.edit && authStore.isUser(4)"
         (click)="settings.edit = true">
        <cds-icon shape="eye" style="margin-left: 0"></cds-icon>
        <span class="nav-text mobile-hidden">View Mode</span>
      </a>


      <clr-dropdown [clrCloseMenuOnItemClick]="false">
        <button class="nav-icon" clrDropdownTrigger>
          <cds-icon shape="cog"></cds-icon>
          <cds-icon shape="angle" direction="down"></cds-icon>
        </button>
        <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
          <h4 class="dropdown-header">{{'Languages.LanguageTitle' | translate}}</h4>
          <div clrDropdownItem (click)="settings.language = 'de'" [class.active]="settings.language === 'de'">
            {{
            'Languages.German' | translate }}</div>
          <div clrDropdownItem (click)="settings.language = 'en'" [class.active]="settings.language === 'en'">
            {{
            'Languages.English' | translate }}</div>
          <div clrDropdownItem (click)="settings.language = 'it'" [class.active]="settings.language === 'it'">
            {{
            'Languages.Italian' | translate }}</div>
          <div class="dropdown-divider"></div>
          <h4 class="dropdown-header">{{'Languages.DateTitle' | translate}}</h4>
          <div clrDropdownItem (click)="settings.locale = 'de'" [class.active]="settings.locale === 'de'">
            {{
            currentDate.toLocaleString('de') }}</div>
          <div clrDropdownItem (click)="settings.locale = 'en'" [class.active]="settings.locale === 'en'">
            {{
            currentDate.toLocaleString('en') }}</div>
          <h4 class="dropdown-header">{{'Misc.Settings' | translate}}</h4>
          <div clrDropdownItem>
            <cds-toggle>
              <label>{{'Helpers.Toggle' | translate}}</label>
              <input type="checkbox" (click)="settings.helper = !settings.helper"
                     [checked]="settings.helper"/>
            </cds-toggle>
          </div>
          <div clrDropdownItem>
            <cds-toggle>
              <label>{{'Misc.PdfHandling' | translate}}</label>
              <input type="checkbox"
                     (click)="settings.pdfHandling === 'download' ? settings.pdfHandling = 'open' : settings.pdfHandling = 'download'"
                     [checked]="settings.pdfHandling === 'download'"/>
            </cds-toggle>
          </div>
          <div class="dropdown-divider"></div>
          <div clrDropdownItem (click)="logout()"><span style="color: var(--cds-alias-status-danger)"><cds-icon shape="logout"></cds-icon>{{ 'Actions.LogOut' | translate }}</span></div>


        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </clr-header>

  <app-maintenance-header></app-maintenance-header>
  <div class="content-container">

    <main class="content-area" [class.login]="authStore.isLoggedIn() === false" style="position: relative; padding-bottom: 0">
      <router-outlet></router-outlet>
      <div class="page-load flex-row-c" *ngIf="loadingRouteConfig">
        <span class="spinner">Loading...</span>
      </div>
    </main>

    <clr-vertical-nav [clr-nav-level]="1" [clrVerticalNavCollapsible]="true" class="nav-trigger--bottom"
                      *ngIf="(auth.$userInfo | async)?.id !== ''">
      <a clrVerticalNavLink routerLink="./dashboard" routerLinkActive="active">
        <cds-icon clrVerticalNavIcon shape="home"></cds-icon>
        {{ 'Header.Dashboard' | translate }}
      </a>

      <a clrVerticalNavLink routerLink="./attractions" routerLinkActive="active"
         [routerLinkActiveOptions]="routerLinkActiveOptions">
        <cds-icon clrVerticalNavIcon shape="map"></cds-icon>
        {{ 'Header.Attractions' | translate }}
      </a>

      <a clrVerticalNavLink routerLink="./attractions/trash" routerLinkActive="active"
         [routerLinkActiveOptions]="routerLinkActiveOptions">
        <cds-icon clrVerticalNavIcon shape="trash"></cds-icon>
        {{ 'Header.Trash' | translate }}
      </a>

      <div class="nav-divider" *ngIf="dashboardStore.attraction?.attraction?.id"></div>

      <!-- Attractions -->
      <clr-vertical-nav-group routerLinkActive="active" *ngIf="dashboardStore.attraction?.attraction?.id && !dashboardStore.unit?.unit?.id"
                              [clrVerticalNavGroupExpanded]="false" [routerLinkActiveOptions]="routerLinkActiveOptions">
        <a routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}" hidden aria-hidden="true"></a>
        <cds-icon clrVerticalNavIcon shape="map-marker"></cds-icon>
        {{dashboardStore.attraction.attraction.attraction_name}}
        <clr-vertical-nav-group-children *clrIfExpanded>
          <a clrVerticalNavLink routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Overview' | translate }}
          </a>
          <a clrVerticalNavLink routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Units' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/ridelogs"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Ridelog' | translate }}
          </a>
          <a clrVerticalNavLink routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/files"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Files' | translate }}
          </a>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>

      <a clrVerticalNavLink routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}" routerLinkActive="active" [routerLinkActiveOptions]="routerLinkActiveOptions" *ngIf="dashboardStore.unit?.unit?.id">
        <cds-icon clrVerticalNavIcon shape="map-marker"></cds-icon>
        {{dashboardStore.attraction.attraction.attraction_name}}
      </a>

      <!-- Units -->

      <clr-vertical-nav-group routerLinkActive="active" *ngIf="dashboardStore.unit?.unit?.id && !dashboardStore.part?.part?.id"
                              [clrVerticalNavGroupExpanded]="false" [routerLinkActiveOptions]="routerLinkActiveOptions">
        <a routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}"
           hidden aria-hidden="true"></a>
        <cds-icon clrVerticalNavIcon shape="organization"></cds-icon>
        {{'Header.Unit' | translate}}: {{dashboardStore.unit.full_code_letter}}

        <clr-vertical-nav-group-children *clrIfExpanded>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Overview' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Parts' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/events"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Events' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/ridelogs"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Ridelog' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/files"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Files' | translate }}
          </a>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>

      <a clrVerticalNavLink routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}" *ngIf="dashboardStore.part?.part?.id">
        <cds-icon clrVerticalNavIcon shape="organization"></cds-icon>
        {{'Header.Unit' | translate}}: {{dashboardStore.unit.full_code_letter}}
      </a>

      <!-- Parts -->

      <clr-vertical-nav-group routerLinkActive="active" *ngIf="dashboardStore.part?.part?.id && !dashboardStore.maintenance?.maintenance?.id"
                              [clrVerticalNavGroupExpanded]="false" [routerLinkActiveOptions]="routerLinkActiveOptions">
        <a
          routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}"
          hidden aria-hidden="true"></a>
        <cds-icon clrVerticalNavIcon shape="objects"></cds-icon>
        {{'Header.Part' | translate}}: {{utils.getI18nString(dashboardStore.part.part.name,
        translate.currentLang)}}

        <clr-vertical-nav-group-children *clrIfExpanded>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Overview' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}/maintenances"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Maintenances' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}/files"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Files' | translate }}
          </a>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>

      <a clrVerticalNavLink routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}" *ngIf="dashboardStore.maintenance?.maintenance?.id">
        <cds-icon clrVerticalNavIcon shape="objects"></cds-icon>
        {{'Header.Part' | translate}}: {{utils.getI18nString(dashboardStore.part.part.name, translate.currentLang)}}
      </a>

      <!-- Maintenances -->

      <clr-vertical-nav-group routerLinkActive="active" *ngIf="dashboardStore.maintenance?.maintenance?.id"
                              [clrVerticalNavGroupExpanded]="false" [routerLinkActiveOptions]="routerLinkActiveOptions">
        <a
          routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}/maintenances/{{dashboardStore.maintenance.maintenance.id}}"
          hidden aria-hidden="true"></a>
        <cds-icon clrVerticalNavIcon shape="wrench"></cds-icon>
        {{'Header.Maintenance' | translate}}:
        {{utils.getI18nString(dashboardStore.maintenance.maintenance.description,
        translate.currentLang)}}

        <clr-vertical-nav-group-children *clrIfExpanded>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}/maintenances/{{dashboardStore.maintenance.maintenance.id}}"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Overview' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}/maintenances/{{dashboardStore.maintenance.maintenance.id}}/reports"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.History' | translate }}
          </a>
          <a clrVerticalNavLink
             routerLink="./attractions/{{dashboardStore.attraction.attraction.id}}/units/{{dashboardStore.unit.unit.id}}/parts/{{dashboardStore.part.part.id}}/maintenances/{{dashboardStore.maintenance.maintenance.id}}/files"
             [routerLinkActiveOptions]="routerLinkActiveOptions" routerLinkActive="active">
            {{ 'Header.Files' | translate }}
          </a>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>


      <div class="nav-divider"></div>


      <clr-vertical-nav-group routerLinkActive="active">
        <cds-icon shape="cog" clrVerticalNavIcon></cds-icon>
        {{ 'Header.GlobalManagement' | translate }}
        <clr-vertical-nav-group-children>
          <a clrVerticalNavLink routerLink="./admin/groups" routerLinkActive="active" *ngIf="authStore.isUser(4)">
            {{'Header.GroupManagement' | translate}}
          </a>
          <a clrVerticalNavLink routerLink="./admin/users" routerLinkActive="active">
            {{'Header.UserManagement' | translate}}
          </a>
          <a clrVerticalNavLink routerLink="./admin/backups" routerLinkActive="active" *ngIf="authStore.isUser(4)">
            {{'Header.BackupManagement' | translate}}
          </a>
        </clr-vertical-nav-group-children>
      </clr-vertical-nav-group>

      <a clrVerticalNavLink routerLink="user" routerLinkActive="active" >
        <cds-icon clrVerticalNavIcon shape="user"></cds-icon>
        {{auth.userInfo.user_mail}}
      </a>

      <a clrVerticalNavLink (click)="openManual()">
        <cds-icon clrVerticalNavIcon shape="book"></cds-icon>
        {{ 'Header.Manual' | translate }}
      </a>


      <div class="nav-divider"></div>
      <a clrVerticalNavLink style="margin-top: auto">
        <cds-icon clrVerticalNavIcon shape="flask"></cds-icon>
        {{ getVersion() }}
      </a>
    </clr-vertical-nav>
  </div>
</clr-main-container>



<app-resource-info-modal></app-resource-info-modal>
<app-change-info-modal></app-change-info-modal>
<app-new-member-modal></app-new-member-modal>
<app-image-upload></app-image-upload>
<app-image-display></app-image-display>

<app-do-maintenance-wizard></app-do-maintenance-wizard>
