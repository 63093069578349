import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { GroupStore } from '../stores/group.store';

export function forbiddenUsernameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let input = control.value as string;
        if (!input) {
            return null;
        }
        return input.includes('localhost') ? { forbiddenName: { value: control.value } } : null;
    };
}

export function confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const passwordControl = control.get('user_password');
        const confirmPasswordControl = control.get('confirm_password');
        if (!passwordControl || !confirmPasswordControl) {
            return null;
        }

        if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
            return null;
        }

        if (passwordControl.value !== confirmPasswordControl.value) {
            confirmPasswordControl.setErrors({ passwordMismatch: true });
            return { passwordMismatch: true };
        } else {
            return null;
        }
    };
}

export function groupTypeValidator(groupStore: GroupStore): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const groupControl = control.get('user_group');
        const typeControl = control.get('user_type');
        const admin_id = groupStore.getIdByName('admin')
        if (!groupControl || !typeControl) {
            return null;
        }

        if (typeControl.value === '4' && groupControl.enabled && groupControl.value !== admin_id) {
            groupControl.patchValue(admin_id);
            groupControl.disable();
            return null;
        } else if (typeControl.value !== '4' && groupControl.disabled) {
            groupControl.enable();
            groupControl.patchValue('');
            return null;
        }

        if (groupControl.value === admin_id && typeControl.value !== 4) {
            groupControl.setErrors({ groupNotAllowed: true });
            return { groupNotAllowed: true };
        }

        if (groupControl.value !== admin_id && typeControl.value === 4) {
            groupControl.setErrors({ adminGroupNeeded: true });
            return { adminGroupNeeded: true };
        }
        return null;
    };
}
