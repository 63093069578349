import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { ApplyRequest, SuccessConfirmation } from '../types/base';
import { AttractionResponse, AttractionRequest, Attraction, AttractionsResponse, TrackInfo, WordmarkList, ChapterList } from '../types/attraction';

@Injectable({
    providedIn: 'root',
})
export class AttractionHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getAttraction(id: string, changes: boolean = false): Observable<AttractionResponse> {
        let params = new HttpParams().append('changes', changes);
        return this.http
            .get<AttractionResponse>('attractions/' + id, { params })
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllAttractions(changes: boolean = false, deleted: boolean = false): Observable<AttractionsResponse> {
        let params = new HttpParams().append('changes', changes);
        if (deleted) {
            params = params.append('deleted', 'true');
        }
        return this.http.get<AttractionsResponse>('attractions/', {params}).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createAttraction(attraction: AttractionRequest, templateId?: string): Observable<AttractionResponse> {
        if (templateId && templateId !== '' && templateId !== 'empty') {
            let params = new HttpParams().append('template', templateId);
            return this.http
                .post<AttractionResponse>('attractions/', attraction, { params: params })
                .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
        } else {
            return this.http
                .post<AttractionResponse>('attractions/', attraction)
                .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
        }
    }

    updateAttraction(id: string, attraction: AttractionRequest): Observable<AttractionResponse> {
        return this.http
            .put<AttractionResponse>('attractions/' + id, attraction)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    applyAttraction(id: string, apply: ApplyRequest): Observable<SuccessConfirmation> {
        return this.http
            .put<SuccessConfirmation>('attractions/' + id + '/apply', apply)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deleteAttraction(id: string): Observable<SuccessConfirmation> {
        return this.http
            .delete<SuccessConfirmation>('attractions/' + id)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getTracks(): Observable<string[]> {
        return this.http.get<string[]>('tracks').pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getTrackInfo(id: string): Observable<TrackInfo> {
      return this.http.get<TrackInfo>('attractions/' + id + '/trackinfo').pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }


}
