import { HttpEvent, HttpResponse, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { i18nString } from '../types/i18nString';
import { Deadline, IntervallArray } from '../types/maintenance';
import { UserInfo } from '../types/user';
import { SettingsService } from './settings.service';
@Injectable({
    providedIn: 'root',
})
export class UtilsService {

    public dateLocation: string = "de-DE"
    constructor(public translate: TranslateService, public settings: SettingsService) { }

    getClrDateString(date: Date) {
        switch (this.translate.currentLang) {
            case 'de':
                return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('.');
            default:
                return 'No date';
        }
    }

    parseDateString(date: string | undefined): Date {
        if (date === undefined) {
            return new Date('0001-01-01');
        }
        if (date.includes("/")) {
            var parts = date.split("/");
            return new Date(Number(parts[2]), Number(parts[0]) - 1, Number(parts[1]));
        } else if (date.includes(".")) {
            var parts = date.split(".");
            return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
        }
        return new Date(Date.parse(date));
    }

  parseDateTimeString(date: string | undefined | null): string {
    if (!date) {
      return ""
    }
    // parse date in this format 2021-03-01T00-00-00
    const parts = date.split("T");
    if (parts.length !== 2) {
      return ""
    }
    const dateParts = parts[0].split("-");
    if (dateParts.length !== 3) {
      return ""
    }
    const timeParts = parts[1].split("-");
    if (timeParts.length !== 3) {
      return ""
    }
    return new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]), Number(timeParts[0]), Number(timeParts[1]), Number(timeParts[2])).toLocaleString();
  }

    toDateString(date: string | undefined): string {
        if (date === undefined) {
            switch (this.translate.currentLang) {
                case 'de':
                    return 'Kein Datum';
                default:
                    return 'No date';
            }
        } else {
            if (new Date(date).getTime() === new Date('0001-01-01').getTime()) {
                switch (this.translate.currentLang) {
                    case 'de':
                        return 'Bei Event';
                    default:
                        return 'On event';
                }
            }
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            switch (this.translate.currentLang) {
                case 'de':
                    return new Date(date).toLocaleDateString('de-DE', options as any);
                case 'en':
                    return new Date(date).toLocaleDateString('en-US', options as any);
            }
            return new Date(date).toLocaleDateString(this.dateLocation, options as any);
        }
        return '';
    }

    toShortDateString(date: string | undefined): string {
        if (date === undefined) {
            switch (this.translate.currentLang) {
                case 'de':
                    return 'Kein Datum';
                case 'en':
                    return 'No date';
            }
        } else {
            if (new Date(date).getTime() === new Date('0001-01-01').getTime()) {
                switch (this.translate.currentLang) {
                    case 'de':
                        return 'Bei Event';
                    case 'en':
                        return 'On event';
                }
            }
            const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
            switch (this.settings.locale) {
                case 'de':
                    return new Date(date).toLocaleDateString('de-DE', options as any);
                case 'en':
                    return new Date(date).toLocaleDateString('en-US', options as any);
            }
            return new Date(date).toLocaleDateString(this.dateLocation, options as any);
        }
        return '';
    }

    toTimeString(date: string | undefined): string {
        if (date === undefined) {
            switch (this.translate.currentLang) {
                case 'de':
                    return 'Keine Uhrzeit';
                case 'en':
                    return 'No Time';
            }
        } else {
            const options = { hour: '2-digit', minute: '2-digit' };
            switch (this.settings.locale) {
                case 'de':
                    return new Date(date).toLocaleTimeString('de-DE', options as any) + " Uhr";
                case 'en':
                    return new Date(date).toLocaleTimeString('en-US', options as any);
            }
            return new Date(date).toLocaleTimeString(this.dateLocation, options as any);
        }
        return '';
    }

    getUserRights(user: UserInfo): string {
        switch (user.user_type) {
            case 4:
                return 'Constants.UserRoles.Admin';
            case 3:
                return 'Constants.UserRoles.Manage';
            case 2:
                return 'Constants.UserRoles.Edit';
            case 1:
                return 'Constants.UserRoles.View';
        }
        return 'Errors.NoRights';
    }

    isOverdue(deadline: Deadline | undefined) {
        if (deadline) {
            if (new Date(deadline.next_completion).getTime() === new Date('0001-01-01').getTime()) {
                return false
            }
            return new Date(deadline.next_completion).getTime() < new Date(deadline.current_date).getTime()
        }
        return false;
    }

    isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
        return event.type === HttpEventType.Response
    }

    isHttpProgressEvent(
        event: HttpEvent<unknown>
    ): event is HttpProgressEvent {
        return (
            event.type === HttpEventType.DownloadProgress ||
            event.type === HttpEventType.UploadProgress
        )
    }

    getI18nString(str: i18nString | undefined | null, lang: string): string {
        if (str === undefined || str === null) {
            return ""
        }
        switch (lang) {
            case "en":
                return str.en || str.de
            case "it":
                return str.it || str.en || str.de
            default: return str.de || str.en || ""
        }
    }

    getI18nArrayAsString(arr: i18nString[] | undefined, lang: string): string {
        if (arr === undefined) {
            return ""
        }
        switch (lang) {
            case "en":
                return arr.map(str => str.en || str.de).join(", ")
            case "it":
                return arr.map(str => str.it || str.en || str.de).join(", ")
            default: return arr.map(str => str.de || str.en || "").join(", ")
        }
    }

    getI18nArrayAsStringArray(arr: i18nString[] | undefined, lang: string): string[] {
        if (arr === undefined) {
            return []
        }
        switch (lang) {
            case "en":
                return arr.map(str => str.en || str.de)
            case "it":
                return arr.map(str => str.it || str.en || str.de)
            default: return arr.map(str => str.de || str.en || "")
        }
    }

    createI18nArray(de: string[], en: string[]): i18nString[] {
        return de.map<i18nString>((de_str, i) => { return { de: de_str, en: en[i] } as i18nString })
    }

    isSameDay(date1: string | Date, date2: string | Date): boolean {
        const _date1 = new Date(date1)
        const _date2 = new Date(date2)
        return _date1.getFullYear() === _date2.getFullYear() &&
            _date1.getMonth() === _date2.getMonth() &&
            _date1.getDate() === _date2.getDate()
    }

    isFuture(date: string | Date, compare: string | Date): boolean {
        const _date1 = new Date(date)
        const _date2 = new Date(compare)
        return _date1.getFullYear() > _date2.getFullYear() ||
            (_date1.getFullYear() === _date2.getFullYear() && _date1.getMonth() > _date2.getMonth()) ||
            (_date1.getFullYear() === _date2.getFullYear() && _date1.getMonth() === _date2.getMonth() && _date1.getDate() > _date2.getDate())

    }

    isPast(date: string | Date, compare: string | Date): boolean {
        const _date1 = new Date(date)
        const _date2 = new Date(compare)
        return _date1.getFullYear() < _date2.getFullYear() ||
            (_date1.getFullYear() === _date2.getFullYear() && _date1.getMonth() < _date2.getMonth()) ||
            (_date1.getFullYear() === _date2.getFullYear() && _date1.getMonth() === _date2.getMonth() && _date1.getDate() < _date2.getDate())

    }

    roundToFirstDecimal(num: number): string {
        return num.toFixed(1)
    }

    compareI18nString(i18n: i18nString | undefined, str: string) {
        if (i18n === undefined) {
            return false
        }
        return i18n.de === str ||
            i18n.en === str ||
            i18n.it === str
    }

    compareI18n(i18n: i18nString, str: i18nString | undefined | null) {
        if (str === undefined || str === null) {
            return false
        }
        return i18n.de === str.de &&
            i18n.en === str.en &&
            i18n.it === str.it
    }

    i18nStringAdd(i18n1: i18nString, i18n2: i18nString, joiner: string = " ") {
        return {
            de: i18n1.de ? i18n1.de + joiner + i18n2.de : "",
            en: i18n1.en ? i18n1.en + joiner + i18n2.en : "",
            it: i18n1.it ? i18n1.it + joiner + i18n2.it : ""
        }
    }

    newi18nString(str: string) {
        return {
            de: str,
            en: str,
            it: str,
        }
    }

    i18nJoin(arr: i18nString[], sep: string) {
        return {
            de: arr.map(s => s.de).join(sep),
            en: arr.map(s => s.en).join(sep),
            it: arr.map(s => s.it).join(sep),
        }

    }

}
