import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UrlService } from 'src/app/core/services/url.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  constructor(private urlService: UrlService, private router: Router, protected sanitizer: DomSanitizer) { }

  public showPdf = false;
  public url: SafeResourceUrl = '';

  ngOnInit(): void {
    // Get id and page from url
    const id = this.router.parseUrl(this.router.url).queryParams.id;
    const page = this.router.parseUrl(this.router.url).queryParams.page;
    if (id && page) {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlService.getUrl('/static/' + id) + "#page=" + page);
      this.showPdf = true;
    }
  }

}
