import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthStore } from '../stores/auth.store';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private _locale: string;
    private _language: string;
    private _helper: boolean;
    private _edit: boolean;
    private _pdfHandling: string;

    constructor(public translate: TranslateService, public authService: AuthStore) {
        const locale = localStorage.getItem('locale');
        if (locale !== null) {
            this._locale = locale
        } else {
            this._locale = 'de-DE'
        }
        translate.setDefaultLang('en');
        this._language = 'en'
        const lang = localStorage.getItem('language');
        if (lang !== null) {
            this.language = lang
        }
        this._helper = true
        const helper = localStorage.getItem('helper') === 'true';
        if (helper !== null) {
            this._helper = helper
        }
        this._edit = false;
        const edit = localStorage.getItem('edit') === 'true';
        if (edit !== null) {
            this._edit = edit;
        }

        this._pdfHandling = 'download'
        const pdfHandling = localStorage.getItem('pdfHandling');
        if (pdfHandling !== null) {
            this._pdfHandling = pdfHandling;
        }



        this.authService.$userInfo.subscribe((value) => {
            if (authService.initalRequest) {
                if (!this.authService.isUser(3)) {
                    this.edit = false;
                }
            }
        });
    }



    set locale(value: string) {
        this._locale = value;
        localStorage.setItem('locale', value)
        window.location.reload()
    }

    get locale(): string {
        return this._locale;
    }

    set language(value: string) {
        this._language = value;
        localStorage.setItem('language', value)
        this.translate.use(value);
    }

    get language(): string {
        return this._language
    }


    set helper(value: boolean) {
        this._helper = value;
        localStorage.setItem('helper', value ? 'true' : 'false')
    }

    get helper(): boolean {
        return this._helper;
    }

    set edit(value: boolean) {
        this._edit = value;
        localStorage.setItem('edit', value ? 'true' : 'false')
    }

    get edit(): boolean {
        return this._edit;
    }

    set pdfHandling(value: string) {
        this._pdfHandling = value;
        localStorage.setItem('pdfHandling', value)
    }

    get pdfHandling(): string {
        return localStorage.getItem('pdfHandling') || 'open'
    }


}
