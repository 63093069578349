import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { ApplyRequest, SuccessConfirmation } from '../types/base';
import { Unit, UnitResponse, UnitRequest, UnitsResponse } from '../types/unit';

@Injectable({
    providedIn: 'root',
})
export class UnitHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getUnit(id: string, unit_id: string, changes: boolean = false): Observable<UnitResponse> {
        let params = new HttpParams().append('changes', changes);
        return this.http
            .get<UnitResponse>('attractions/' + id + '/units/' + unit_id, {params})
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllUnits(id: string, changes: boolean = false, deleted: boolean = false): Observable<UnitsResponse> {
        let params = new HttpParams().append('changes', changes);
        if (deleted) {
          params = params.append('deleted', 'true');
        }
        return this.http
            .get<UnitsResponse>('attractions/' + id + '/units/', {params})
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createUnit(id: string, unit: UnitRequest, templateId?: string): Observable<UnitResponse> {

        if (templateId && templateId !== '' && templateId !== 'empty') {
            let params = new HttpParams().append('template', templateId);
            return this.http
                .post<UnitResponse>('attractions/' + id + '/units/', unit, { params: params })
                .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
        } else {
            return this.http
                .post<UnitResponse>('attractions/' + id + '/units/', unit)
                .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
        }
    }

    updateUnit(
        id: string,
        unit_id: string,
        unit: UnitRequest
    ): Observable<UnitResponse> {
        return this.http
            .put<UnitResponse>('attractions/' + id + '/units/' + unit_id, unit)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    applyUnit(attraction_id: string, id: string, apply: ApplyRequest): Observable<SuccessConfirmation> {
        return this.http
            .put<SuccessConfirmation>('attractions/' + attraction_id + '/units/' + id + '/apply', apply)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deleteUnit(id: string, unit_id: string): Observable<SuccessConfirmation> {
        return this.http
            .delete<SuccessConfirmation>('attractions/' + id + '/units/' + unit_id)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }
}
