import { Component, OnInit } from '@angular/core';

import {
    ActivatedRoute,
    IsActiveMatchOptions,
    Params, ResolveEnd, ResolveStart,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router
} from '@angular/router';
import { AuthHttpService } from './core/http/auth.http';
import { AuthStore } from './core/stores/auth.store';
import { UtilsService } from './core/services/utils.service';
import { SettingsService } from './core/services/settings.service';
import { DashboardStore } from './core/stores/dashboardStore';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'maintenance-app-web';
    public currentDate: Date = new Date(Date.now())
    public routerLinkActiveOptions: IsActiveMatchOptions = {
        matrixParams: 'ignored',
        queryParams: 'ignored',
        fragment: 'ignored',
        paths: 'exact'
    };
    public loadingRouteConfig: boolean = false;
    constructor(
        private authService: AuthHttpService,
        public authStore: AuthStore,
        public dashboardStore: DashboardStore,
        public router: Router,
        public route: ActivatedRoute,
        public auth: AuthStore,
        public utils: UtilsService,
        public settings: SettingsService,
        public translate: TranslateService
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
                this.loadingRouteConfig = true;
            } else if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
                this.loadingRouteConfig = false;
            }
        });
    }

    logout() {
        this.authService.getLogout().subscribe((value) => {
            if (value.success) {
                this.auth.clear();
                this.router.navigate(['login']);
            }
        });
    }

    openManual() {
        if (environment.MANUAL_URL) {
            window.open(environment.MANUAL_URL, '_blank')?.focus();
        }
    }

    getVersion(): string {
        return environment.VERSION;
    }

}
