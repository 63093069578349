import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LandingpageModule } from 'src/app/modules/landingpage/landingpage.module';
import { AuthStore } from '../stores/auth.store';
import { AuthHttpService } from '../http/auth.http';
import { ca } from 'date-fns/locale';

@Injectable()
export class AdminOrManagerGuard  {
    constructor(private router: Router, private authStore: AuthStore, private http: AuthHttpService) { }
    guard(url: string): Observable<boolean | UrlTree> {
        return new Observable<boolean | UrlTree>((observer) => {
            if (this.authStore.initalRequest) {
                if (this.authStore.isUser(3)) {
                    observer.next(true);
                    observer.complete();
                } else {
                    observer.next(this.router.createUrlTree(['/login', { next: url }]));
                    observer.complete();
                }
            } else {
                this.http.getCurrent().subscribe((val) => {
                    if (typeof val === 'string') {
                        this.authStore.userInfo = this.authStore.noAuth;
                        return;
                    }
                    this.authStore.userInfo = val.user;
                    if (this.authStore.isUser(3)) {
                        observer.next(true);
                        observer.complete();
                    } else {
                        observer.next(this.router.createUrlTree(['/login', { next: url }]));
                        observer.complete();
                    }
                }, (error) => {
                    observer.next(this.router.createUrlTree(['/login', { next: url }]));
                    observer.complete();
                });
            }
        });

    }
    canMatch = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
        return this.guard(segments.join('/'));
    };
}

@Injectable()
export class AdminGuard  {
    constructor(private router: Router, private authStore: AuthStore, private http: AuthHttpService) { }
    guard(url: string): Observable<boolean | UrlTree> {
        return new Observable<boolean | UrlTree>((observer) => {
            if (this.authStore.initalRequest) {
                if (this.authStore.isUser(4)) {
                    observer.next(true);
                    observer.complete();
                } else {
                    observer.next(this.router.createUrlTree(['/login', { next: url }]));
                    observer.complete();
                }
            } else {
                this.http.getCurrent().subscribe((val) => {
                    if (typeof val === 'string') {
                        this.authStore.userInfo = this.authStore.noAuth;
                        return;
                    }
                    this.authStore.userInfo = val.user;
                    if (this.authStore.isUser(4)) {
                        observer.next(true);
                        observer.complete();
                    } else {
                        observer.next(this.router.createUrlTree(['/login', { next: url }]));
                        observer.complete();
                    }
                }, (error) => {
                    observer.next(this.router.createUrlTree(['/login', { next: url }]));
                    observer.complete();
                });
            }
        });
    }
    canMatch = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
        return this.guard(segments.join('/'));
    };
}

@Injectable()
export class LoginGuard  {
    constructor(private router: Router, private authStore: AuthStore, private http: AuthHttpService) { }
    guard(url: string): Observable<boolean | UrlTree> {
        return new Observable<boolean | UrlTree>((observer) => {
            if (this.authStore.initalRequest) {
                if (this.authStore.isLoggedIn()) {
                    observer.next(true);
                    observer.complete();
                } else {
                    observer.next(this.router.createUrlTree(['/login', { next: url }]));
                    observer.complete();
                }
            } else {
                this.http.getCurrent().subscribe((val) => {
                    if (typeof val === 'string') {
                        this.authStore.userInfo = this.authStore.noAuth;
                        observer.next(this.router.createUrlTree(['/login', { next: url }]));
                        observer.complete();
                        return;
                    }
                    this.authStore.userInfo = val.user;
                    if (this.authStore.isLoggedIn()) {
                        observer.next(true);
                        observer.complete();
                    } else {
                        observer.next(this.router.createUrlTree(['/login', { next: url }]));
                        observer.complete();
                    }
                }, (error) => {
                    observer.next(this.router.createUrlTree(['/login', { next: url }]));
                    observer.complete();
                });
            }

        });

    }
    canMatch = (route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
        return this.guard(segments.join('/'));
    };
}
