<clr-wizard #doMaintenanceWizard [clrWizardOpen]="false" (clrWizardOnCancel)="onCancel()"
    (clrWizardOpenChange)="checkWizard($event)" (clrWizardOnFinish)="onComplete()">
    <clr-wizard-title>{{ 'Forms.DoMaintenanceWizard.Title' | translate }}</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{ 'Forms.Cancel' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{ 'Forms.Back' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{ 'Forms.Next' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{ 'Forms.Save' | translate }}</clr-wizard-button>


    <clr-wizard-page #pageOne
        [clrWizardPageNextDisabled]="!(maintenanceService.unitFound.valid && maintenanceService.attractionFound.valid)">
        <ng-template clrPageTitle>{{ 'Forms.DoMaintenanceWizard.Page1Title' | translate }}</ng-template>
        <ng-template clrPageNavTitle>{{ 'Forms.DoMaintenanceWizard.Page1Nav' | translate }}</ng-template>
        <div class="card" *ngIf="maintenanceService.attraction">
            <div class="card-header">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div cds-layout="horizontal gap:md">
                        <img [src]="'assets/logos/' + maintenanceService.attraction.attraction.attraction_type + '.svg'"
                            width="24" height="24">
                        <span>{{ maintenanceService.attraction.attraction.attraction_name }}</span>
                    </div>
                    <clr-toggle-container style="margin-top: 0;">
                        <clr-toggle-wrapper style="width: 100%">
                            <input type="checkbox" clrToggle [formControl]="maintenanceService.attractionFound" />
                            <label>{{ 'Actions.Found' | translate }}</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                </div>
            </div>
            <div class="card-block">
                <table class="table table-compact table-noborder" style="margin: 0;">
                    <tbody>
                        <tr>
                            <td class="left" style="width: 50%;">{{ 'Grid.Attraction.Group' | translate }}</td>
                            <td class="left">{{
                                groupStore.getNameById(maintenanceService.attraction.attraction.parent_id) }}</td>
                        </tr>
                        <tr>
                            <td class="left">{{ 'Grid.Attraction.Park' | translate }}</td>
                            <td class="left">{{ maintenanceService.attraction.attraction.park_name }}</td>
                        </tr>
                        <tr>
                            <td class="left">{{ 'Grid.Attraction.Header' | translate }}</td>
                            <td class="left">{{ maintenanceService.attraction.attraction.attraction_name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card" *ngIf="maintenanceService.unit">
            <div class="card-header">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div cds-layout="horizontal gap:md">
                        <span>{{ 'Header.Unit' | translate }}:</span>
                        <span>{{ maintenanceService.unit.full_code_letter }}</span>
                    </div>
                    <clr-toggle-container style="margin-top: 0;">
                        <clr-toggle-wrapper style="width: 100%">
                            <input type="checkbox" clrToggle [formControl]="maintenanceService.unitFound" />
                            <label>{{ 'Actions.Found' | translate }}</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                </div>
            </div>
            <div class="card-block">
                <table class="table table-compact table-noborder" style="margin: 0;">
                    <tbody>
                        <tr>
                            <td class="left" style="width: 50%;">{{ 'Grid.Unit.CodeLetter' | translate }}</td>
                            <td class="left">{{ maintenanceService.unit.full_code_letter }}</td>
                        </tr>
                        <tr>
                            <td class="left">{{ 'Grid.Unit.Name' | translate }}</td>
                            <td class="left">{{ utils.getI18nString(maintenanceService.unit.unit.name,
                                translate.currentLang)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </clr-wizard-page>

    <clr-wizard-page #pageTwo
        [clrWizardPageNextDisabled]="!(maintenanceService.partFound.valid && maintenanceService.detailsFound.valid)">
        <ng-template clrPageTitle>{{ 'Forms.DoMaintenanceWizard.Page2Title' | translate }}</ng-template>
        <ng-template clrPageNavTitle>{{ 'Forms.DoMaintenanceWizard.Page2Nav' | translate }}</ng-template>
        <div class="card" *ngIf="maintenanceService.part">
            <div class="card-header">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div cds-layout="horizontal gap:md">
                        <span>{{ 'Header.Part' | translate }}:</span>
                        <span>{{ utils.getI18nString(maintenanceService.part.part.name, translate.currentLang) }}</span>
                    </div>
                    <clr-toggle-container style="margin-top: 0;">
                        <clr-toggle-wrapper style="width: 100%">
                            <input type="checkbox" clrToggle [formControl]="maintenanceService.partFound" />
                            <label>{{ 'Actions.Found' | translate }}</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                </div>
            </div>
            <div class="card-block">
                <table class="table table-compact table-noborder" style="margin: 0;">
                    <tbody>
                        <tr>
                            <td class="left" style="width: 50%;">{{ 'Grid.Parts.HeaderGroup' | translate }}</td>
                            <td class="left">{{ utils.getI18nString(maintenanceService.part.part.group,
                                translate.currentLang)}}</td>
                        </tr>
                        <tr>
                            <td class="left">{{ 'Grid.Parts.HeaderName' | translate }}</td>
                            <td class="left">{{ utils.getI18nString(maintenanceService.part.part.name,
                                translate.currentLang)}}</td>
                        </tr>
                        <tr>
                            <td class="left">{{ 'Grid.Parts.Number' | translate }}</td>
                            <td class="left">{{ maintenanceService.part.part.number }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card" *ngIf="maintenanceService.maintenance">
            <div class="card-header">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div cds-layout="horizontal gap:md">
                        <span>{{ 'Header.Maintenance' | translate }}:</span>
                        <span>{{ utils.getI18nString(maintenanceService.maintenance.maintenance.description,
                            translate.currentLang)
                            }}</span>
                    </div>
                    <clr-toggle-container style="margin-top: 0;">
                        <clr-toggle-wrapper style="width: 100%">
                            <input type="checkbox" clrToggle [formControl]="maintenanceService.detailsFound" />
                            <label>{{ 'Actions.Found' | translate }}</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                </div>
            </div>
            <div class="card-block">
                <table class="table table-compact table-noborder" style="margin: 0;">
                    <tbody>
                        <tr>
                            <td class="left" style="width: 50%;">{{ 'Grid.Maintenance.Description' | translate }}</td>
                            <td class="left">{{
                                utils.getI18nString(maintenanceService.maintenance.maintenance.description,
                                translate.currentLang)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </clr-wizard-page>

    <clr-wizard-page #pageThree
        [clrWizardPageNextDisabled]="!maintenanceService.findings.valid || maintenanceService.uploadValidArr.includes(false)"
        (clrWizardPageOnLoad)="maintenanceService.checkSkip(doMaintenanceWizard);">
        <ng-template clrPageTitle>{{ 'Forms.DoMaintenanceWizard.Page3Title' | translate }}</ng-template>
        <ng-template clrPageNavTitle>{{ 'Forms.DoMaintenanceWizard.Page3Nav' | translate }}</ng-template>
        <h4>{{ 'Messages.CheckForFindings' | translate }}</h4>
        <span *ngFor="let method of maintenanceService.maintenance?.maintenance?.methods" class="label label-warning">{{
            'Constants.TestMethods.'+
            method.id | translate }}</span>
        <div *ngIf="maintenanceService.isSplit() !== undefined"
            style="display: flex; justify-content: center; align-items: center; gap: 24px;">
            <div>
                <clr-icon shape="resource-pool" size="40" class="is-info"></clr-icon>
            </div>

            <h4 style="margin-top: 0;">{{ 'Misc.Step' | translate }}
                {{maintenanceService.getSplit(maintenanceService.isSplit())}}</h4>
        </div>

        <div class="card" *ngFor="let finding of maintenanceService.maintenance?.maintenance?.findings; let i = index">
            <div class="card-block">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div cds-layout="horizontal gap:md">
                        <span></span>
                        <span>{{ utils.getI18nString(finding, translate.currentLang)}}</span>
                    </div>
                </div>
            </div>
            <div class="card-action"
                style="display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 12px;">

                <div class="btn-group" [id]="'finding_group_'+ i">
                    <div class="radio btn btn-success-outline">
                        <input type="radio" [id]="'finding_'+ i + '_okay'" [value]="true" [name]="'finding_group_'+i"
                            [formControl]="getFormControl(maintenanceService.findings.controls[i])">
                        <label [for]="'finding_'+ i + '_okay'">{{ 'Actions.Ok' | translate }}</label>
                    </div>
                    <div class="radio btn btn-warning-outline">
                        <input type="radio" [value]="false" [id]="'finding_'+ i + '_notokay'"
                            [name]="'finding_group_'+i"
                            [formControl]="getFormControl(maintenanceService.findings.controls[i])">
                        <label [for]="'finding_'+ i + '_notokay'"><span style="font-weight: bold">{{ 'Actions.NotOk'
                                |
                                translate }}</span></label>
                    </div>
                </div>

                <clr-textarea-container *ngIf="getFormControl(maintenanceService.findings.controls[i]).value === false"
                    style="width: 100%; margin-top: 12px;">
                    <label>{{ 'Forms.DoMaintenanceWizard.Page3.Comment.Label' | translate }}</label>
                    <textarea clrTextarea [formControl]="getFormControl(maintenanceService.findingComments.controls[i])"
                        style="width: 100%;" [name]="'finding_comment_' + i"></textarea>
                    <clr-control-helper>{{ 'Forms.Optional' | translate }}</clr-control-helper>
                </clr-textarea-container>

                <app-image-selector *ngIf="getFormControl(maintenanceService.findings.controls[i]).value === false"
                    accept="image/jpeg" [group]="i" [parent]="maintenanceService.maintenance?.maintenance?.id"
                    style="width: 100%; margin-top: 12px;" [(valid)]="maintenanceService.uploadValidArr[i]">
                </app-image-selector>

            </div>
        </div>

    </clr-wizard-page>

    <clr-wizard-page #pageFour [clrWizardPageNextDisabled]="!maintenanceService.measures.valid"
        (clrWizardPageOnLoad)="maintenanceService.getMeasures(); maintenanceService.getNextMaintenance();"
        (clrWizardPageCustomButton)="doCustomClick($event)">
        <ng-template clrPageTitle>{{ 'Forms.DoMaintenanceWizard.Page4Title' | translate }}</ng-template>
        <ng-template clrPageNavTitle>{{ 'Forms.DoMaintenanceWizard.Page4Nav' | translate }}</ng-template>

        <div *ngIf="maintenanceService.measures.controls.length === 0"
            style="display: flex; justify-content: center; align-items: center">
            <div>
                <clr-icon shape="success-standard" size="72" class="is-success"></clr-icon>
            </div>
            <h3 style="margin-top: 0;">{{ 'Forms.DoMaintenanceWizard.Page3.NoMeasuresRequired' | translate }}</h3>
        </div>
        <div *ngIf="maintenanceService.measures.controls.length !== 0"
            style="display: flex; justify-content: center; align-items: center">
            <div>
                <clr-icon shape="warning-standard" size="48" class="is-danger"></clr-icon>
            </div>
            <h4 style="margin-top: 0;">{{ 'Forms.DoMaintenanceWizard.Page3.MeasuresRequired' | translate }}</h4>
        </div>
        <div class="card" *ngFor="let measure of maintenanceService.measures.controls; let i = index">
            <div class="card-block">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <div cds-layout="horizontal gap:md">
                        <span></span>
                        <span>{{ utils.getI18nString(maintenanceService.maintenance!.maintenance.measures[i],
                            translate.currentLang)}}</span>
                    </div>
                </div>
            </div>
            <div class="card-action"
                style="display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 12px;">

                <div class="btn-group" [id]="'measure_group'+ i">
                    <div class="radio btn btn-success-outline">
                        <input type="radio" [id]="'measure_'+ i + '_performed'" [value]="true"
                            [name]="'measure_group'+i"
                            [formControl]="getFormControl(maintenanceService.measures.controls[i])">
                        <label [for]="'measure_'+ i + '_performed'">{{ 'Actions.Performed' | translate }}</label>
                    </div>
                    <div class="radio btn btn-warning-outline">
                        <input type="radio" [value]="false" [id]="'measure_'+ i + '_postponed'"
                            [name]="'measure_group'+i"
                            [formControl]="getFormControl(maintenanceService.measures.controls[i])">
                        <label [for]="'measure_'+ i + '_postponed'"><span style="font-weight: bold">{{
                                'Actions.Postponed'
                                |
                                translate }}</span></label>
                    </div>
                </div>
            </div>
        </div>


        <clr-textarea-container style="margin-top: 12; width: 100%">
            <label>{{ 'Forms.DoMaintenanceWizard.Page3.Comment.Label' | translate }}</label>
            <textarea clrTextarea [formControl]="maintenanceService.comment" style="width: 100%;"></textarea>
            <clr-control-helper>{{ 'Forms.Optional' | translate }}</clr-control-helper>
        </clr-textarea-container>

        <ng-template clrPageButtons>
            <clr-wizard-button [type]="'cancel'">{{ 'Forms.Cancel' | translate }}</clr-wizard-button>
            <clr-wizard-button [type]="'previous'">{{ 'Forms.Back' | translate }}</clr-wizard-button>
            <clr-wizard-button [type]="'finish'">{{ 'Forms.Save' | translate }}</clr-wizard-button>
            <clr-wizard-button [type]="'custom-finish'" *ngIf="maintenanceService.nextMaintenance">
                <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <span style="height: 0.6rem; line-height: 0.6rem">{{ 'Forms.SaveAndNext' | translate }}</span>
                    <span
                        style="font-size: 0.5rem; height: 0.5rem; line-height: 0.6rem; margin-top: 2px;">{{getNextMaintenanceDescription()}}</span>
                </div>
            </clr-wizard-button>
        </ng-template>

    </clr-wizard-page>
</clr-wizard>