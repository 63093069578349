import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { SuccessConfirmation } from '../types/base';
import { Group, GroupRequest, GroupResponse, GroupsResponse } from '../types/group';

@Injectable({
    providedIn: 'root',
})
export class GroupHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getGroup(group_id: string): Observable<GroupResponse> {
        return this.http.get<GroupResponse>('groups/' + group_id).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllGroups(): Observable<GroupsResponse> {
        return this.http.get<GroupsResponse>('groups/').pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createGroup(group: GroupRequest): Observable<GroupResponse> {
        return this.http.post<GroupResponse>('groups/', group).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    updateGroup(group_id: string, group: GroupRequest): Observable<GroupResponse> {
        return this.http.put<GroupResponse>('groups/' + group_id, group).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deleteGroup(group_id: string): Observable<SuccessConfirmation> {
        return this.http.delete<SuccessConfirmation>('groups/' + group_id).pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }
}
