import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { ApplyRequest, SuccessConfirmation } from '../types/base';
import { Maintenance, MaintenanceResponse, MaintenanceRequest, MaintenancesResponse } from '../types/maintenance';

@Injectable({
    providedIn: 'root',
})
export class AttractionMaintenanceHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getMaintenance(id: string, unit_id: string, part_id: string, maintenance_id: string, changes: boolean = false): Observable<MaintenanceResponse> {
        let params = new HttpParams().append('changes', changes);
        return this.http
            .get<MaintenanceResponse>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id, { params })
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllMaintenances(id: string, unit_id: string, part_id: string, changes: boolean = false): Observable<MaintenancesResponse> {
        let params = new HttpParams().append('changes', changes);
        return this.http
            .get<MaintenancesResponse>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/', {params})
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createMaintenance(id: string, unit_id: string, part_id: string, maintenance: MaintenanceRequest, templateId: string): Observable<MaintenanceResponse> {
        if (templateId && templateId !== '' && templateId !== 'empty') {
            let params = new HttpParams().append('template', templateId);
            return this.http
                .post<MaintenanceResponse>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/', maintenance, { params: params })
                .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
        } else {
            return this.http
                .post<MaintenanceResponse>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/', maintenance)
                .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
        }


    }

    updateMaintenance(
        id: string, unit_id: string, part_id: string,
        maintenance_id: string,
        maintenance: MaintenanceRequest
    ): Observable<MaintenanceResponse> {
        return this.http
            .put<MaintenanceResponse>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id, maintenance)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    applyMaintenance(attraction_id: string, unit_id: string, part_id: string, id: string, apply: ApplyRequest): Observable<SuccessConfirmation> {
        return this.http
            .put<SuccessConfirmation>('attractions/' + attraction_id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + id + '/apply', apply)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deleteMaintenance(id: string, unit_id: string, part_id: string, maintenance_id: string): Observable<SuccessConfirmation> {
        return this.http
            .delete<SuccessConfirmation>('attractions/' + id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }
}
