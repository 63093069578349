<clr-modal [clrModalOpen]="service.fileQueue.length !== 0" [clrModalClosable]="false">

    <h3 class="modal-title">{{ 'Header.FileUpload' | translate }}</h3>
    <div class="modal-body">
        <div class="card-list">
            <div class="card-list-item" *ngFor="let file of service.fileQueue; let i = index">

                <app-image-container *ngIf="file.selectedFile.preview && file.selectedFile.preview !== ''"
                    [src]="file.selectedFile.preview">
                </app-image-container>

                <span style="flex: 0 0 20%;">
                    {{getFileSize(file.selectedFile.file.size)}} Mb
                </span>

                <div style="flex: 1;" *ngIf="file.selectedFile.obs && !file.selectedFile.obs.closed"
                    style="padding-right: 12px;">
                    <clr-progress-bar clrLabeled=true [clrValue]=" file.percent">
                    </clr-progress-bar>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()">{{ 'Forms.Cancel' | translate }}</button>
    </div>

</clr-modal>