import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthHttpService } from '../http/auth.http';
import { Change } from '../types/base';
import { UserInfo } from '../types/user';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
    providedIn: 'root',
})
export class AuthStore {
    public noAuth: UserInfo = {
        user_type: 0,
        user_first_name: '',
        user_last_name: '',
        user_mail: '',
        id: '',
        parent_id: '',
        origin: '',
        group_name: '',
        user_status: 'active',
        changes: [],
    }

    private userInfoSubject: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(this.noAuth);
    public initalRequest: boolean = false;

    constructor() {
        this.userInfoSubject.subscribe((val) => {
            Sentry.setUser({id: val.id});
        });
    }

    get userInfo(): UserInfo {
        return this.userInfoSubject.value;
    }

    set userInfo(userInfo: UserInfo) {
        this.userInfoSubject.next(userInfo);
    }

    get $userInfo(): Observable<UserInfo> {
        return new Observable((fn) => this.userInfoSubject.subscribe(fn));
    }

    clear() {
        this.userInfo = this.noAuth;
    }

    isLoggedIn(): boolean {
        return this.userInfo && this.userInfo.id !== '';
    }

    isUser(level: number): boolean {
        if (this.userInfo && this.userInfo.id !== '') {
            return this.userInfo.user_type >= level
        }
        return this.userInfo && this.userInfo.id !== '' && this.userInfo.user_type >= level
    }

}
