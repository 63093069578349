import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { HttpClientModule } from '@angular/common/http';

import {
  ClarityIcons,
  angleIcon,
  banIcon,
  bubbleExclamationIcon,
  checkIcon,
  downloadIcon,
  helpInfoIcon,
  historyIcon,
  hostIcon,
  languageIcon,
  pencilIcon,
  plusIcon,
  rackServerIcon,
  copyIcon,
  timesIcon,
  uploadIcon,
  userIcon,
  wrenchIcon,
  imageIcon,
  infoStandardIcon,
  trashIcon,
  newIcon,
  homeIcon,
  mapMarkerIcon,
  bookIcon,
  cogIcon,
  mapIcon,
  organizationIcon,
  objectsIcon,
  eyeIcon, flaskIcon, logoutIcon, lockIcon, envelopeIcon,
} from '@cds/core/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsModule } from '@cds/angular';
import { ChangePasswordModalComponent } from './user-profile/change-password-modal/change-password-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteConfirmModalComponent } from './delete-confirm-modal/delete-confirm-modal.component';
import { FilterByInputPipe } from '../core/pipes/filter-by-input.pipe';
import { CustomComboboxComponent } from './custom-combobox/custom-combobox.component';
import { NotificationAnkerComponent } from './notification-anker/notification-anker.component';
import { ResourceInfoModalComponent } from './resource-info-modal/resource-info-modal.component';
import { ImageSelectorComponent } from './image-selector/image-selector.component';
import { ImageContainerComponent } from './image-container/image-container.component';
import { ImageDisplayComponent } from './image-display/image-display.component';
import { FilterAttractionsPipe } from '../core/pipes/filter-attractions';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { FilterDueDatePipe } from '../core/pipes/filter-due-date';
import { filterReportHistory } from '../core/pipes/filter-report-history';
import { CalendarHeaderComponent } from './calendar-header/calendar-header.component';
import { CalendarModule } from 'angular-calendar';
import { ChangeInfoModalComponent } from './change-info-modal/change-info-modal.component';
import { NewMemberModalComponent } from './new-member-modal/new-member-modal.component';
import { I18nInputComponent } from './i18n-input/i18n-input.component';
import { LangInputComponent } from './lang-input/lang-input.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { ImageLinkComponent } from './image-link/image-link.component';
import { MaintenanceHeaderComponent } from '../modules/data/maintenances/maintenance-header/maintenance-header.component';
import { ErrorDisplayComponent } from './error-display/error-display.component';
import { SuccessDisplayComponent } from './success-display/success-display.component';
import { ToolmarkPipe, WordmarkPipe } from '../core/pipes/wordmark.pipe';
import { FilterPartByGroupPipe } from '../core/pipes/part-filter';
import { i18nStringPipe } from '../core/pipes/i18nString.pipe';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { ImageLinkEditorComponent } from './image-link/image-link-editor/image-link-editor.component';
import { ImageLinkViewerComponent } from './image-link/image-link-viewer/image-link-viewer.component';
import { ImageLinkHeaderComponent } from './image-link/image-link-header/image-link-header.component';
import { ImageLinkCreatorComponent } from './image-link/image-link-creator/image-link-creator.component';
import {DoMaintenanceWizardComponent} from "./do-maintenance-wizard/do-maintenance-wizard.component";
import {MaintenanceListComponent} from "./maintenance-list/maintenance-list.component";
import {UserProfileComponent} from "./user-profile/user-profile.component";

@NgModule({
    declarations: [
        DeleteConfirmModalComponent,
        FilterByInputPipe,
        FilterAttractionsPipe,
        FilterDueDatePipe,
        filterReportHistory,
        CustomComboboxComponent,
        NotificationAnkerComponent,
        ResourceInfoModalComponent,
        ImageSelectorComponent,
        ImageContainerComponent,
        ImageDisplayComponent,
        ImageSelectorComponent,
        ImageUploadComponent,
        CalendarHeaderComponent,
        ChangeInfoModalComponent,
        NewMemberModalComponent,
        I18nInputComponent,
        LangInputComponent,
        BreadcrumbsComponent,
        ImageLinkComponent,
        MaintenanceHeaderComponent,
        ErrorDisplayComponent,
        SuccessDisplayComponent,
        WordmarkPipe,
        ToolmarkPipe,
        FilterPartByGroupPipe,
        i18nStringPipe,
        PdfViewerComponent,
        ImageLinkEditorComponent,
        ImageLinkViewerComponent,
        ImageLinkHeaderComponent,
        ImageLinkCreatorComponent,
        DoMaintenanceWizardComponent,
      MaintenanceListComponent,
    ],
    imports: [CommonModule, ClarityModule, CdsModule, ReactiveFormsModule, TranslateModule, CalendarModule, FormsModule, RouterModule],
    exports: [
        ClarityModule,
        CdsModule,
        CommonModule,
        HttpClientModule,
        TranslateModule,
        ReactiveFormsModule,
        DeleteConfirmModalComponent,
        FilterByInputPipe,
        FilterAttractionsPipe,
        FilterDueDatePipe,
        filterReportHistory,
        CustomComboboxComponent,
        NotificationAnkerComponent,
        ResourceInfoModalComponent,
        ImageSelectorComponent,
        ImageContainerComponent,
        ImageDisplayComponent,
        ImageSelectorComponent,
        ImageUploadComponent,
        CalendarHeaderComponent,
        ChangeInfoModalComponent,
        FormsModule,
        NewMemberModalComponent,
        I18nInputComponent,
        LangInputComponent,
        BreadcrumbsComponent,
        ImageLinkComponent,
        MaintenanceHeaderComponent,
        ErrorDisplayComponent,
        SuccessDisplayComponent,
        WordmarkPipe,
        ToolmarkPipe,
        FilterPartByGroupPipe,
        i18nStringPipe,
        PdfViewerComponent,
        DoMaintenanceWizardComponent,
        ImageLinkEditorComponent,
        ImageLinkViewerComponent,
        ImageLinkHeaderComponent,
        ImageLinkCreatorComponent,
      MaintenanceListComponent,

    ],
})
export class SharedModule {
    constructor() {
        ClarityIcons.addIcons(userIcon);
        ClarityIcons.addIcons(angleIcon);
        ClarityIcons.addIcons(copyIcon);
        ClarityIcons.addIcons(plusIcon);
        ClarityIcons.addIcons(checkIcon);
        ClarityIcons.addIcons(timesIcon);
        ClarityIcons.addIcons(uploadIcon);
        ClarityIcons.addIcons(downloadIcon);
        ClarityIcons.addIcons(wrenchIcon);
        ClarityIcons.addIcons(historyIcon);
        ClarityIcons.addIcons(bubbleExclamationIcon);
        ClarityIcons.addIcons(languageIcon);
        ClarityIcons.addIcons(hostIcon);
        ClarityIcons.addIcons(banIcon);
        ClarityIcons.addIcons(infoStandardIcon);
        ClarityIcons.addIcons(pencilIcon);
        ClarityIcons.addIcons(imageIcon);
        ClarityIcons.addIcons(helpInfoIcon);
        ClarityIcons.addIcons(trashIcon);
        ClarityIcons.addIcons(newIcon);
        ClarityIcons.addIcons(homeIcon);
        ClarityIcons.addIcons(mapMarkerIcon);
        ClarityIcons.addIcons(bookIcon);
        ClarityIcons.addIcons(cogIcon);
        ClarityIcons.addIcons(mapIcon);
        ClarityIcons.addIcons(organizationIcon);
        ClarityIcons.addIcons(objectsIcon);
        ClarityIcons.addIcons(wrenchIcon);
        ClarityIcons.addIcons(eyeIcon);
        ClarityIcons.addIcons(flaskIcon);
        ClarityIcons.addIcons(logoutIcon);
        ClarityIcons.addIcons(lockIcon);
        ClarityIcons.addIcons(envelopeIcon);

      ClarityIcons.addIcons(['checks', '<svg xmlns="http://www.w3.org/2000/svg"  style="line-height: 0.5" height="24" width="24" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M342.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 402.7 54.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z"/></svg>']);
    }
}

