import {Component, OnInit} from '@angular/core';
import {UserHttpService} from "../../core/http/user.http";
import {AuthHttpService} from "../../core/http/auth.http";
import {AuthStore} from "../../core/stores/auth.store";
import {AuthLog} from "../../core/types/auth";
import {ChangeResponse} from "../../core/types/base";
import {CalendarMonthModule} from "angular-calendar";
import {ClrCheckboxModule, ClrCommonFormsModule, ClrDatepickerModule, ClrRadioModule} from "@clr/angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {SharedModule} from "../shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ChangePasswordModalComponent} from "./change-password-modal/change-password-modal.component";
import {UserInfo} from "../../core/types/user";
import {last} from "rxjs/operators";
import {UtilsService} from "../../core/services/utils.service";
import {UserWizardComponent} from "./user-wizard/user-wizard.component";

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [
    CalendarMonthModule,
    ClrCheckboxModule,
    ClrCommonFormsModule,
    ClrDatepickerModule,
    ClrRadioModule,
    FormsModule,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    ChangePasswordModalComponent,
    UserWizardComponent
  ],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit {
  constructor(private http: UserHttpService, private auth: AuthHttpService, public authStore: AuthStore, private route: ActivatedRoute, private router: Router, public utils: UtilsService) {}

  public authLog: AuthLog[] = [];
  public userLog: ChangeResponse[] = [];
  public userLogSpliceLimit: number = 3;
  public authLogSpliceLimit: number = 3;
  public showPasswordModal: boolean = false;
  public showUserModal: boolean = false;
  public user: UserInfo | undefined = undefined;
  async ngOnInit() {

    let id = this.route.snapshot.paramMap.get('user_id');
    if (id && id !== 'current') {
      this.http.getUser(id).subscribe((res) => {
        this.user = res.user;
        this.getUserData();
      });
    } else {
      this.user = this.authStore.userInfo
      this.getUserData();
    }
  }

  getUserData() {
    if (!this.user) return;
    this.auth.getAuthLogForUser(this.user.id).subscribe((log) => {
      this.authLog = log.auth_log.reverse();
    });
    this.http.getUserLog(this.user.id ).subscribe((log) => {
      this.userLog = log.changes;
    });
  }

  logout() {
    this.auth.getLogout().subscribe((value) => {
      if (value.success) {
        this.authStore.clear();
        this.router.navigate(['login']);
      }
    });
  }
}
