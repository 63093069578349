import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {EMPTY, Observable, of, throwError} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    constructor() { }

    currentError: HttpErrorResponse | null = null;
    showDetails = false;

    handleError(error: HttpErrorResponse, caught: Observable<any>): Observable<any> {
        if (error.status === 503) {
            return throwError(error);
        }
        if (error.status === 401) {
            return EMPTY
        }
        if (error) {
            this.currentError = error;
        }
        return throwError(error);
    }

    clearError(): void {
        this.currentError = null;
        this.showDetails = false;
    }

    openDetails(): void {
        this.showDetails = true;
    }

    closeDetails(): void {
        this.showDetails = false;
    }

    handleSuccess(response: any): Observable<any> {
        return of(response);
    }
}
