import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class ImageDisplayService {

    public show: boolean = false
    public src: any = ""
    constructor() { }

    displayImage(src: any) {
        this.src = src
        this.show = true
    }


}
