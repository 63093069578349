import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {Group, GroupResponse} from '../types/group';
@Injectable({
    providedIn: 'root',
})
export class GroupStore {
    private groupSubject: BehaviorSubject<GroupResponse[]> = new BehaviorSubject<GroupResponse[]>([]);

    constructor() { }

    get groups(): GroupResponse[] {
        return this.groupSubject.value;
    }

    get $groups(): Observable<GroupResponse[]> {
        return new Observable((fn) => this.groupSubject.subscribe(fn));
    }

    set groups(group: GroupResponse[]) {
        this.groupSubject.next(group);
    }

    getNameById(id: string | undefined): string {
        const arr = this.groupSubject.value.filter((g) => g.group.id === id);
        if (arr.length === 1) {
            return arr[0].group.group_name;
        } else {
            return 'Errors.NoGroupFound';
        }
    }

    getIdByName(name: string): string {
        const arr = this.groupSubject.value.filter((g) => g.group.group_name === name);
        if (arr.length === 1) {
            return arr[0].group.id;
        } else {
            return '';
        }
    }

    updateGroup(group: GroupResponse) {
        this.groupSubject.next(this.groupSubject.value.map((g) => (g.group.id === group.group.id ? group : g)));
    }

    deleteGroup(group: GroupResponse) {
        this.groupSubject.next(this.groupSubject.value.filter((g) => g.group.id !== group.group.id));
    }

    addGroup(group: GroupResponse) {
        this.groupSubject.next(this.groupSubject.value.concat(group));
    }
}
