<cds-file *ngIf="(multiple || (selectedFiles.length === 0 && fileInfo.length === 0))">
    <label for="file" *ngIf="type==='image'">{{'Forms.Files.LabelImage' | translate}}</label>
    <label for="file" *ngIf="type==='pdf'">{{'Forms.Files.LabelPdf' | translate}}</label>
    <label for="file" *ngIf="type==='text'">{{'Forms.Files.LabelWord' | translate}}</label>
    <input type="file" [id]="'file'+type" (change)="onFileSelected($event)" [multiple]="multiple" [accept]="accept" />
</cds-file>
<form [formGroup]="form">
    <div class="card-list" *ngIf="selectedFiles.length !== 0">
        <div class="card-list-item" *ngFor="let file of selectedFiles; let i = index" formArrayName="fileInfo">

            <app-image-container *ngIf="file.preview && file.preview !== ''" [src]="file.preview"></app-image-container>
            <img src="./assets/logos/pdf.svg" *ngIf="!file.preview && type === 'pdf'">
            <img src="./assets/logos/txt.svg" *ngIf="!file.preview && type === 'text'">

            <span style="flex: 0 0 10%;">
                {{getFileSize(file.file.size)}} Mb
            </span>
            <div style="flex: 1;">
                <div formGroupName="{{i}}" clrForm class="clr-form-compact">
                    <clr-input-container style="margin-top: 0;">
                        <label class="clr-col-3">Name</label>
                        <input clrInput formControlName="name" class="clr-col-9" />
                    </clr-input-container>
                    <clr-input-container *ngIf="type === 'image'">
                        <label class="clr-col-3">Beschreibung</label>
                        <input clrInput formControlName="description" class="clr-col-9" placeholder="Optional" />
                    </clr-input-container>
                    <clr-select-container *ngIf="type !== 'image'">
                        <label class="clr-col-3">Dokument</label>
                        <select clrSelect name="options" formControlName="description" class="clr-col-9">
                            <option value="wordmark">Wortmarkenlisten</option>
                            <option value="manual">Bedienungsanleitung</option>
                        </select>
                    </clr-select-container>
                    <clr-input-container *ngIf="type !== 'image'">
                        <label class="clr-col-3">Kapitel</label>
                        <input clrInput formControlName="group" class="clr-col-9" />
                    </clr-input-container>
                </div>
            </div>
            <div class="btn-group-vertical ">
                <button class="btn btn-danger btn-icon btn-sm" (click)="onRemove(i)">
                    <cds-icon shape="times"></cds-icon> Entfernen
                </button>
            </div>
        </div>
    </div>
</form>
<div class="card-list" *ngIf="fileInfo.length !== 0">
    <div class="card-list-item" *ngFor="let file of fileInfo; let i = index">
        <app-image-container *ngIf="type === 'image'"
            [src]="urlService.getUrl('/static/' + file.id)"></app-image-container>
        <img src="./assets/logos/pdf.svg" *ngIf="type === 'pdf'" (click)="openPDF(file)">
        <img src="./assets/logos/docx.svg" *ngIf="type === 'text' && file.file_type ==='DOCX'" (click)="openWord(file)">
        <img src="./assets/logos/txt.svg" *ngIf="type === 'text' && file.file_type ==='TXT'" (click)="openWord(file)">
        <span style="flex:1; display: block;" *ngIf="type === 'image'">
            {{file.file_name}}<br> <span
                style="font-size:small; display: block; line-break: anywhere;">{{file.file_description}}</span>
        </span>
        <span style="flex:1; display: block;" *ngIf="type !== 'image'">
            {{file.file_name}}<br> <span
                style="font-size:small; display: block; line-break: anywhere;">{{file.file_description}}: Chapter
                {{file.group}} </span>
        </span>
        <div>
            <span class="success" style="color: hsl(93, 67%, 38%); padding-right: 0.6rem;">Erfolgreich
                hochgeladen</span>
            <button class="btn btn-danger btn-icon btn-sm" (click)="onDelete(i)">
                <cds-icon shape="times"></cds-icon> Löschen
            </button>
        </div>
    </div>
</div>