import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminOrManagerGuard, LoginGuard } from './core/guards/auth-guard.service';
import { PdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';
import {UnloadResolver} from "./core/resolvers/unloader.resolver";
import {GroupResolver} from "./core/resolvers/group.resolver";
import {UserProfileComponent} from "./shared/user-profile/user-profile.component";

const routes: Routes = [
    {
        path: 'pdf',
        component: PdfViewerComponent,
    },
    {
        path: 'login',
        loadChildren: () => import('./modules/landingpage/landingpage.module').then((m) => m.LandingpageModule),
        runGuardsAndResolvers: 'always',
        resolve: {
          unload: UnloadResolver,
        },
        data: {
          unload: ['*']
        }
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canMatch: [AdminOrManagerGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          unload: UnloadResolver,
          groups: GroupResolver,
        },
        data: {
          unload: ['*']
        }
    },
    {
        path: 'attractions',
        loadChildren: () => import('./modules/data/data.module').then((m) => m.DataModule),
        canMatch: [LoginGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          groups: GroupResolver
        }
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canMatch: [LoginGuard],
        runGuardsAndResolvers: 'always',
        resolve: {
          unload: UnloadResolver,
          groups: GroupResolver,
        },
        data: {
          unload: ['*']
        }
    },
    {
      path: 'user',
      canMatch: [LoginGuard],
      component: UserProfileComponent,
      resolve: {
        unload: UnloadResolver,
        groups: GroupResolver
      },
      data: {
        unload: ['*']
      }
    },
    {
        path: '**',
        redirectTo: 'dashboard',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
