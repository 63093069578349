import { LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from "@angular/router";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { httpInterceptorProviders } from './core/interceptors';
import { AdminGuard, AdminOrManagerGuard, LoginGuard } from './core/guards/auth-guard.service';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import * as Sentry from "@sentry/angular-ivy";

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { SettingsService } from './core/services/settings.service';
registerLocaleData(localeDe);
registerLocaleData(localeEn);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

    ],
    providers: [
        httpInterceptorProviders,
        LoginGuard,
        AdminOrManagerGuard,
        AdminGuard,
        {
            provide: LOCALE_ID,
            deps: [SettingsService],
            useFactory: (LocaleService: { locale: string; }) => LocaleService.locale
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
