<div class="header-nav outer-div" *ngIf="!isPDF">
    <div class="inner-div">
        <div class="nav-link nav-icon-text" style="background: none;">
            <a class="nav-icon" routerLink="./dashboard">
                <cds-icon shape="home" style="margin: 0"></cds-icon>
            </a>
            <span>
                <span class="nav-text">
                    {{'Title' | translate}}
                </span>
            </span>

            <span *ngFor="let p of currentPath">
                <span class="divider">/</span>
                <a *ngIf="p.url !== ''" class="nav-text" [routerLink]="[p.url]">{{p.text || (p.key || '' |
                    translate)}}</a>
                <span *ngIf="p.url === ''" class="nav-text">{{p.text || (p.key || '' | translate)}}</span>
            </span>
        </div>
    </div>
</div>