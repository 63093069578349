import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { SuccessConfirmation } from '../types/base';
import { Report, ReportResponse, ReportRequest, ReportsResponse } from '../types/report';

@Injectable({
    providedIn: 'root',
})
export class ReportHttpService {
    constructor(private http: HttpClient, private errorService: ErrorService) { }

    getReport(attraction_id: string, unit_id: string, part_id: string, maintenance_id: string, report_id: string): Observable<ReportResponse> {
        return this.http
            .get<ReportResponse>(
                'attractions/' + attraction_id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id + '/reports/' + report_id
            )
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    getAllReports(attraction_id: string, unit_id: string, part_id: string, maintenance_id: string): Observable<ReportsResponse> {
        return this.http
            .get<ReportsResponse>('attractions/' + attraction_id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id + '/reports/')
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createReport(attraction_id: string, unit_id: string, part_id: string, maintenance_id: string, report: ReportRequest): Observable<ReportResponse> {
        return this.http
            .post<ReportResponse>('attractions/' + attraction_id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id + '/reports/', report)
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    createMultipleReport(attraction_id: string, unit_id: string, same_origin_units: string[], part_id: string, maintenance_id: string, report: ReportRequest, ): Observable<ReportsResponse> {
      let params = new HttpParams();
      if (same_origin_units && same_origin_units.length > 0) {
        for (let unit_id of same_origin_units) {
          params = params.append('sameOriginUnit', unit_id);
        }
      }
      const options = { params: params };
      return this.http
        .post<ReportsResponse>('attractions/' + attraction_id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id + '/reports/', report, options)
        .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    updateReport(
        attraction_id: string, unit_id: string, part_id: string,
        maintenance_id: string,
        report_id: string,
        report: ReportRequest
    ): Observable<ReportResponse> {
        return this.http
            .put<ReportResponse>(
                'attractions/' + attraction_id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id + '/reports/' + report_id,
                report
            )
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }

    deleteReport(attraction_id: string, unit_id: string, part_id: string, maintenance_id: string, report_id: string): Observable<SuccessConfirmation> {
        return this.http
            .delete<SuccessConfirmation>(
                'attractions/' + attraction_id + '/units/' + unit_id + '/parts/' + part_id + '/maintenances/' + maintenance_id + '/reports/' + report_id
            )
            .pipe(catchError((err, caught) => this.errorService.handleError(err, caught)));
    }
}
